import { useEffect, useState } from 'react';

interface WindowsDimensionsType {
  width: number;
  height: number;
}

export const useWindowSize = (): WindowsDimensionsType => {
  const htmlRoot = document.getElementsByTagName('html')[0];
  const [width, setWidth] = useState<number>(htmlRoot.clientWidth);
  const [height, setHeight] = useState<number>(htmlRoot.clientHeight);

  useEffect(() => {
    const handleResize = (): void => {
      setWidth(htmlRoot.clientWidth);
      setHeight(htmlRoot.clientHeight);
    };

    window.addEventListener('resize', handleResize);

    return (): void => window.removeEventListener('resize', handleResize);
  }, [htmlRoot.clientWidth, htmlRoot.clientHeight]);

  return { width, height };
};
