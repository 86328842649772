import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { useInvoiceListing } from '../contexts/InvoiceListingContext';
import { useUser } from '../../users/UserContext';
import useInvoices from '../useInvoices';
import InvoiceStats from '../InvoiceStats';
import { UserRole } from '../../users/user.role';
import IngLogo from '../assets/ing.png';

function DownloadInvoicesButtons(): React.ReactElement {
  const { t } = useTranslation('common');

  const {
    user: { role },
  } = useUser();
  const { downloadInvoices, downloadInvoicesIng } = useInvoices();
  const { data, url } = useInvoiceListing();

  return (
    <>
      {role === UserRole.ADMIN ? (
        <>
          <InvoiceStats />
          <Row style={{ paddingTop: '10px' }}>
            <Col>
              <Space>
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    downloadInvoices(data?.data);
                  }}
                  disabled={data?.data?.length === 0}
                >
                  {`${t('invoice.downloadFilteredInvoices')} (${data?.data?.length})`}
                </Button>
                <Button
                  onClick={() => {
                    downloadInvoicesIng(url);
                  }}
                  disabled={data?.data?.length === 0}
                >
                  <img src={IngLogo} width={24} alt='ING Logo' />
                  {`${t('invoice.downloadIng')}`}
                </Button>
              </Space>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default DownloadInvoicesButtons;
