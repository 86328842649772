import React, { ReactElement, ReactNode, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useFetchTimesheets from '../../../../hooks/timesheets/useFetchTimesheets';

import { initialFilterValsTimesheet, initialPagValue } from '../../../../utils/validationSchemas';

import {
  FilterOptionsTimesheet,
  PaginationOptions,
  FetchedTimesheetType,
  ResponseMetaType,
  ColumnSortingOptions,
} from '../timesheet-types';
import config from '../../../../config';
import { useUser } from '../../users/UserContext';
import { UserRole } from '../../users/user.role';

interface TimesheetListingType {
  filterOptions: FilterOptionsTimesheet;
  setFilterOptions: (filters: FilterOptionsTimesheet) => void;
  pagination: PaginationOptions;
  setPagination: (pagOptions: PaginationOptions) => void;
  sort: ColumnSortingOptions;
  setSort: (sortOptions: ColumnSortingOptions) => void;
  data: { data: FetchedTimesheetType[]; meta: ResponseMetaType; links: Record<string, string> };
  loading: boolean;
  error: {} | undefined;
  getTimesheets: () => void;
  url: string;
}

const defaultValue: TimesheetListingType = {
  filterOptions: { from: undefined, to: undefined, project: undefined, userEmail: undefined },
  setFilterOptions: () => {},
  pagination: { page: 0, pageSize: 0 },
  setPagination: () => {},
  sort: { column: undefined, order: undefined },
  setSort: () => {},
  data: {
    data: [
      {
        id: 0,
        hours: 0,
        description: '',
        userId: 0,
        from: '',
        to: '',
        projectId: 0,
        user: { email: '', firstName: '', lastName: '' },
        project: { name: '' },
      },
    ],
    meta: { currentPage: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0, sortBy: [] },
    links: {},
  },
  loading: false,
  error: undefined,
  getTimesheets: () => {},
  url: '',
};

export const TimesheetListingContext = React.createContext<TimesheetListingType>(defaultValue);

export const TimesheetListingProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {
    user: { role, email },
  } = useUser();

  const [filterOptions, setFilterOptions] = useState<FilterOptionsTimesheet>(() => {
    const fromStorage = sessionStorage.getItem('timesheet');
    if (fromStorage !== null) {
      const storageParams = new URLSearchParams(fromStorage);
      if (!storageParams.has('userEmail') && role === UserRole.PM) {
        storageParams.set('userEmail', email);
      }
      return initialFilterValsTimesheet(storageParams);
    } else {
      if (!queryParams.has('userEmail') && role === UserRole.PM) {
        queryParams.set('userEmail', email);
      }
      return initialFilterValsTimesheet(queryParams);
    }
  });

  const [pagination, setPagination] = useState<PaginationOptions>(() =>
    initialPagValue(queryParams),
  );

  const [sort, setSort] = useState<ColumnSortingOptions>({ column: undefined, order: undefined });

  const { data, error, loading, getTimesheets, url } = useFetchTimesheets(
    filterOptions,
    pagination,
    {
      adminCasePath: config.api.timesheetPath,
      collabCasePath: config.api.timesheetMe,
    },
    sort,
  );

  const values = useMemo(() => {
    return {
      filterOptions,
      setFilterOptions,
      pagination,
      setPagination,
      sort,
      setSort,
      data,
      error,
      loading,
      getTimesheets,
      url,
    };
  }, [url, filterOptions, pagination, sort, data, error, loading, getTimesheets]);

  return (
    <TimesheetListingContext.Provider value={values}>{children}</TimesheetListingContext.Provider>
  );
};

export const useTimesheetListing = (): TimesheetListingType => {
  return useContext(TimesheetListingContext);
};
