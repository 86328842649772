import React, { useState } from 'react';
import { Button, Dropdown, Layout, Space, theme } from 'antd';
import { useFetch } from 'use-http';
import {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames';
import { useLocalStorage } from 'usehooks-ts';
import { useWindowSize } from '../../hooks/general/useWindowSize';

import MainMenu from './MainMenu';

import s from '../App.module.scss';

import LogoSvg from '../../assets/logo.svg';

import config from '../../config';

const { Content, Sider, Header } = Layout;
const { api } = config;
const { baseUrl, usersMe } = api;

interface PropType {
  children: React.ReactNode;
}

const initialCollapseState = (): boolean => {
  const fromStorage: string | null = sessionStorage.getItem('collapsed');
  if (fromStorage !== null) {
    return JSON.parse(fromStorage);
  } else {
    return true;
  }
};

const Page: React.ElementType = ({ children }: PropType) => {
  const { t } = useTranslation('common');

  const [collapsed, setCollapsed] = useState(() => initialCollapseState());
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [, setToken] = useLocalStorage('token', { bearer: '' });
  const [, setIsOnboarded] = useLocalStorage('is_onboarded', false);
  const [, setUser] = useLocalStorage('user', { email: '', role: '' });
  const navigate = useNavigate();
  const { data = {}, error, loading } = useFetch(`${baseUrl}${usersMe}`, {}, []);

  const { width } = useWindowSize();

  const handleCollapse = (value: boolean): void => {
    sessionStorage.setItem('collapsed', JSON.stringify(!value));
    setCollapsed((currentValue) => !currentValue);
  };

  return (
    <Layout className={classnames(s.layout, s.customSider)} hasSider={true}>
      <Sider
        className={s.customSiderAside}
        trigger={null}
        collapsedWidth={0}
        collapsible
        collapsed={collapsed}
      >
        <div className={s.logoContainer}>
          <img src={LogoSvg} alt='Triumvirate Labs' />
        </div>
        <MainMenu />
      </Sider>
      <Layout className={classnames('site-layout', s.customHeaderLayout)}>
        <Header style={{ padding: '0 30px 0 0', background: colorBgContainer }}>
          <Button
            className={s.customTrigger}
            type='text'
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => handleCollapse(collapsed)}
            style={{
              marginLeft: collapsed || width > 480 ? 0 : 200,
            }}
          />

          <Dropdown
            placement='bottomRight'
            menu={{
              items: [
                {
                  key: '3',
                  label: (
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={(e) => {
                        navigate(config.routes.profile);
                      }}
                    >
                      {t('profile.title')}
                    </a>
                  ),
                },
                {
                  key: '2',
                  label: (
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={(e) => {
                        navigate(config.routes.changePassword);
                      }}
                    >
                      {t('changePassword.title')}
                    </a>
                  ),
                },
                {
                  key: '1',
                  label: (
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={(e) => {
                        e.preventDefault();
                        setToken({
                          bearer: '',
                        });
                        setUser({ email: '', role: '' });
                        setIsOnboarded(false);
                        sessionStorage.clear();
                        navigate(config.routes.login);
                      }}
                    >
                      {t('login.logout')}
                    </a>
                  ),
                },
              ],
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              {width < 480 ? (
                <Space>
                  <UserOutlined />
                  <DownOutlined />
                </Space>
              ) : (
                <Space>
                  {!loading && (
                    <>
                      <UserOutlined />
                      <span>
                        {error !== undefined ? '' : `${data?.firstName} ${data?.lastName}`}
                      </span>
                      <DownOutlined />
                    </>
                  )}
                </Space>
              )}
            </a>
          </Dropdown>
        </Header>
        <Content className={s.content}>
          <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Page;
