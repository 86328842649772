import { useCallback, useState } from 'react';
import { useFetch } from 'use-http';

import config from '../../../config';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

const { api } = config;
const { baseUrl, forgotPassword } = api;

interface UseForgotPasswordType {
  loading: boolean;
  error: Error | undefined;
  submit: (data: { email: string }) => void;
  submitted: boolean;
  resetForm: () => void;
}
export default function useForgotPassword(): UseForgotPasswordType {
  const { t } = useTranslation('common');
  const { loading, error, post } = useFetch(`${baseUrl}${forgotPassword}`);
  const [submitted, setSubmitted] = useState(false);

  const submit = useCallback(
    (data: { email: string }) => {
      post({
        email: data.email,
      })
        .then(() => setSubmitted(true))
        .catch(() => {
          return notification.error({
            message: t('common.error'),
            description: t('forgotPassword.submitEmailError'),
            placement: 'topLeft',
          });
        });
    },
    [post, t],
  );

  const resetForm = useCallback(() => setSubmitted(false), [setSubmitted]);

  return { submit, error, loading, submitted, resetForm };
}
