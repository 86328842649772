import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Popconfirm, Row, Space, Table, Tag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { useProjectListing } from '../contexts/ProjectListingContext';
import { useModifyProject } from '../../../../hooks/projects/useModifyProject';

import { ProjectType } from '../project.type';
import { ProjectStatus } from '../project.status';

import { ProjectManagerType } from '../project-types';

import config from '../../../../config';

function ProjectDataTable(): React.ReactElement {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const { deleteProject, loadingDelete } = useModifyProject();

  const { data, error, loading, pagination, getProjects, setPagination } = useProjectListing();
  const { page, pageSize } = pagination;

  const confirmDelete = async (id: string): Promise<void> => {
    const newPage = (data?.meta?.totalItems - 1) % data?.meta?.itemsPerPage === 0 ? page - 1 : page;
    await deleteProject(id);
    setPagination({ page: newPage, pageSize });
    if (newPage === page) {
      await getProjects();
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: number) => {
        if (type === ProjectType.EXTERNAL) {
          return 'Extern';
        } else if (type === ProjectType.INTERNAL) {
          return 'Intern';
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => {
        let color = 'yellow';
        const text: Record<number, string> = {
          [ProjectStatus.ACTIVE]: t('project.active'),
          [ProjectStatus.INACTIVE]: t('project.inactive'),
        };

        if (status === ProjectStatus.ACTIVE) {
          color = 'green';
        }
        return (
          <Tag color={color} key={status}>
            {text[status]}
          </Tag>
        );
      },
    },
    {
      title: 'Project managers',
      dataIndex: 'projectManagers',
      key: 'projectManagers',
      render: (projectManagers: ProjectManagerType[]) => {
        if (projectManagers.length > 0) {
          return projectManagers.map((manager: ProjectManagerType) => (
            <div key={manager.manager_id}>
              <Tag color='geekblue'>{`${manager.user.firstName} ${manager.user.lastName}`}</Tag>
            </div>
          ));
        }
      },
    },

    {
      title: 'Actions',
      render: ({ id }: { id: number }) => {
        return (
          <Row>
            <Space>
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  navigate(config.routes.projectsEdit.replace(':id', `${id}`));
                }}
              ></Button>
              <Popconfirm
                title={t('project.deleteTitle')}
                description={t('project.deleteDescription')}
                okText={t('project.yes')}
                cancelText={t('project.no')}
                onConfirm={() => {
                  confirmDelete(`${id}`);
                }}
              >
                <Button icon={<DeleteOutlined />} danger></Button>
              </Popconfirm>
            </Space>
          </Row>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={error != null || !Array.isArray(data?.data) ? [] : data.data}
      columns={columns}
      loading={loading || loadingDelete}
      bordered
      rowKey='id'
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '15', '20', '25', '30'],
        current: page >= 1 ? page : 1,
        total: typeof data?.meta?.totalItems !== 'undefined' ? data?.meta?.totalItems : 0,
        position: ['bottomCenter'],
        pageSize,
        onChange: (page, pageSize) => {
          localStorage.setItem('pageSize', `${pageSize}`);
          setPagination({ page, pageSize });
        },
      }}
    />
  );
}

export default ProjectDataTable;
