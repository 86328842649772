import { notification } from 'antd';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import config from '../../../config';
import { InvoiceStatus } from './invoice.status';
import { downloadFileInBrowser } from '../../../utils/file';
import { FetchedInvoiceType, InvoiceArray } from './invoice-types';

const { api } = config;
const {
  baseUrl,
  invoicesPath,
  invoicesDeletePath,
  invoiceFileDownloadPath,
  invoicesChangeStatusPath,
  invoiceFileDownloadIngPath,
} = api;

interface UseInvoicesType {
  loadingDelete: boolean;
  loadingChangeStatus: boolean;
  deleteInvoice: (id: string) => void;
  downloadInvoice: (invoice: FetchedInvoiceType) => Promise<void>;
  changeInvoiceStatus: (id: string, status: InvoiceStatus) => Promise<void>;
  loading: boolean;
  loadingDownload: boolean;
  error: Error | undefined;
  downloadInvoices: (data: InvoiceArray) => Promise<void>;
  downloadInvoicesIng: (url: string) => Promise<void>;
}

export default function useInvoices(): UseInvoicesType {
  const { t } = useTranslation('common');

  const { loading, error } = useFetch(`${baseUrl}${invoicesPath}`);

  const { loading: loadingDownload, get: getInvoiceFileDownload } = useFetch(baseUrl, {
    responseType: 'blob',
  });

  const { get: getIngPatternDownload } = useFetch(`${baseUrl}${invoiceFileDownloadIngPath}`, {
    responseType: 'blob',
  });

  const {
    delete: remove,
    loading: loadingDelete,
    response: deleteResponse,
    error: deleteError,
  } = useFetch(baseUrl);

  const {
    patch: changeStatus,
    loading: loadingChangeStatus,
    response: changeStatusResponse,
    error: changeStatusError,
  } = useFetch(baseUrl);

  useEffect(() => {
    if (deleteResponse.ok) {
      notification.success({
        message: t('common.success'),
        description: t('invoice.deleteInvoiceSuccessfully'),
        placement: 'topLeft',
      });
    }
  }, [deleteResponse.ok, t]);

  useEffect(() => {
    if (deleteError != null) {
      notification.error({
        message: t('common.error'),
        description: t('invoice.deleteInvoiceFailed'),
        placement: 'topLeft',
      });
    }
  }, [deleteError, t]);

  useEffect(() => {
    if (changeStatusResponse.ok) {
      notification.success({
        message: t('common.success'),
        description: t('invoice.changeStatusSuccessfully'),
        placement: 'topLeft',
      });
    }
  }, [changeStatusResponse.ok, t]);

  useEffect(() => {
    if (changeStatusError != null) {
      notification.error({
        message: t('common.error'),
        description: t('invoice.changeStatusFailed'),
        placement: 'topLeft',
      });
    }
  }, [changeStatusError, t]);

  const deleteInvoice = useCallback(
    async (id: string) => {
      try {
        await remove(`${invoicesDeletePath}`.replace(':id', id));
      } catch (ex) {
        console.log(ex);
      }
    },
    [remove],
  );

  const changeInvoiceStatus = useCallback(
    async (id: string, status: InvoiceStatus) => {
      try {
        await changeStatus(
          `${invoicesChangeStatusPath}`.replace(':id', id).replace(':status', `${status}`),
        );
      } catch (ex) {
        console.log(ex);
      }
    },
    [changeStatus],
  );

  const downloadInvoice = useCallback(
    async (invoice: FetchedInvoiceType) => {
      const fileDownloadData = await getInvoiceFileDownload(
        `${invoiceFileDownloadPath}`.replace(':fileName', invoice?.fileName),
      );
      const file = new Blob([fileDownloadData], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${invoice?.fileName}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    [getInvoiceFileDownload],
  );

  const downloadInvoicesIng = useCallback(
    async (url: string): Promise<void> => {
      const fileDownloadData = await getIngPatternDownload(url);
      downloadFileInBrowser(fileDownloadData, url, 'ing.xlsx');
    },
    [getIngPatternDownload],
  );

  const downloadInvoices = useCallback(
    async (data: InvoiceArray): Promise<void> => {
      if (typeof data !== 'undefined') {
        for (const invoice of data) {
          await downloadInvoice(invoice);
        }
      }
    },
    [downloadInvoice],
  );

  return {
    loading,
    loadingDelete,
    loadingDownload,
    loadingChangeStatus,
    deleteInvoice,
    changeInvoiceStatus,
    downloadInvoice,
    error,
    downloadInvoices,
    downloadInvoicesIng,
  };
}
