import React from 'react';
import Page from '../../common/Page';
import { LockOutlined } from '@ant-design/icons';
import { Button, Form, Input, Col, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import config from '../../../config';

const { Title } = Typography;

const ResetPassword: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <Page>
        <Title level={2}>{t('changePassword.title')}</Title>
        <Row>
          <Space className='w-100' direction='vertical' size='large'>
            <Col lg={4}>
              <Form
                name='change_password'
                className='password-form'
                initialValues={{ remember: true }}
              >
                <h3>{t('changePassword.passwordLabel') ?? ''}</h3>
                <Form.Item
                  name='password'
                  rules={[{ required: true, message: t('changePassword.passwordError') ?? '' }]}
                >
                  <Input
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    placeholder={t('changePassword.passwordPlaceholder') ?? ''}
                  />
                </Form.Item>
                <h3>{t('changePassword.rePasswordLabel') ?? ''}</h3>
                <Form.Item
                  name='password-repeat'
                  rules={[{ required: true, message: t('changePassword.rePasswordError') ?? '' }]}
                >
                  <Input
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    placeholder={t('changePassword.rePasswordPlaceholder') ?? ''}
                  />
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button type='primary' htmlType='submit' className='login-form-button'>
                      {t('common.saveBtn') ?? ''}
                    </Button>
                    <Link to={config.routes.invoices}>{t('common.cancelBtn') ?? ''}</Link>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Space>
        </Row>
      </Page>
    </>
  );
};

export default ResetPassword;
