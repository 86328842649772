import { useTranslation } from 'react-i18next';
import { UserStatus } from '../../components/pages/users/user.status';

interface StatusOptions {
  value: number;
  label: string;
}

export function useUserStatus(): StatusOptions[] {
  const { t } = useTranslation('common');

  return [
    {
      value: UserStatus.INACTIVE,
      label: t('users.inactive'),
    },
    {
      value: UserStatus.ACTIVE,
      label: t('users.active'),
    },
    {
      value: UserStatus.PENDING,
      label: t('users.pending'),
    },
  ];
}
