import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Form, Row, Select, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useUserStatus } from '../../../../hooks/users/useUserStatus';
import { useUserListing } from '../contexts/UserListingContext';
import { useUserEmailFilterContext } from '../../../../general-purpose-contexts/UserEmailFilterContext';
import { getPageSize } from '../../../common/DataTableDefaults';

function UserFilters(): React.ReactElement {
  const { t } = useTranslation('common');
  const [form] = Form.useForm();

  const statusOptions = useUserStatus();
  const { options } = useUserEmailFilterContext();
  const { setFilterOptions } = useUserListing();

  const { setPagination } = useUserListing();

  const handleFilteringOptions = (): void => {
    setFilterOptions(form.getFieldsValue());
    setPagination({ page: 1, pageSize: getPageSize() });
  };

  return (
    <Card>
      <Form
        name='filter-users'
        form={form}
        layout='vertical'
        wrapperCol={{
          span: 20,
          offset: 0,
        }}
      >
        <Row>
          <Col lg={6}>
            <Form.Item label={t('users.mail') ?? ''} name='email'>
              <Select style={{ width: '100%' }} allowClear showSearch options={options} />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item label={t('users.lastName')} name='lastName'>
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item label={t('users.listStatusPlaceholder')} name='status'>
              <Select className='w-100' options={statusOptions} allowClear></Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify={'end'}>
          <Col>
            <Button
              htmlType='button'
              icon={<SearchOutlined />}
              onClick={() => {
                handleFilteringOptions();
              }}
            >
              {t('common.filterTitle') ?? ''}
            </Button>
            <Button
              htmlType='reset'
              type={'text'}
              onClick={() => {
                form.resetFields();
                handleFilteringOptions();
              }}
            >
              {t('common.clearBtn') ?? ''}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default UserFilters;
