import { useFetch } from 'use-http';
import { useCallback, useEffect } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

import config from '../../../config';
import { ProjectStatus } from './project.status';
import { useNavigate } from 'react-router-dom';

interface CreateProjectType {
  name: string;
  code: string;
  type: string;
  projectManagers?: number[];
}

interface UseProjectAddHookType {
  loading: boolean;
  error: Error | undefined;
  createProject: (data: CreateProjectType) => void;
}

const { api } = config;
const { baseUrl, projectsPath } = api;

export function useProjectAdd(): UseProjectAddHookType {
  const { post, loading, error, data } = useFetch(`${baseUrl}${projectsPath}`);
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isNaN(data?.id)) {
      notification.success({
        message: 'Success',
        description: t('project.saveSuccess'),
        placement: 'topLeft',
      });
      return;
    }
    if (error !== undefined) {
      notification.error({
        message: 'Error',
        description: t('project.saveError'),
        placement: 'topLeft',
      });
    }
  }, [error, data, t]);

  const createProject = useCallback(
    async ({ name, code, type, projectManagers }: CreateProjectType) => {
      try {
        await post({ name, code, status: ProjectStatus.ACTIVE, type, projectManagers });
        navigate(config.routes.projects);
      } catch (ex) {}
    },
    [navigate, post],
  );

  return {
    loading,
    error,
    createProject,
  };
}
