import { Card, Row, Collapse, Col, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import useFetch from 'use-http';

import { useInvoiceListing } from './contexts/InvoiceListingContext';

import { InvoiceStatsType } from './invoice-types';
import config from '../../../config';
import { formatNumber } from '../../../utils/formatNumber';
import { formatMoney } from '../../../utils/formatMoney';

const { Panel } = Collapse;

function InvoiceStats(): React.ReactElement {
  const { t } = useTranslation('common');

  const { url } = useInvoiceListing();

  const statsUrl = useMemo(() => {
    const queryParams: string = url.slice(url.indexOf('?'));

    return `${config.api.baseUrl}${config.api.invoicesStatsPath}${queryParams}`;
  }, [url]);

  const { data, loading } = useFetch<InvoiceStatsType>(statsUrl, { data: {} }, [statsUrl]);

  return (
    <Spin spinning={loading}>
      <Collapse defaultActiveKey={['1']}>
        <Panel header={` ${t('invoice.summary')}`} key='1'>
          <Row justify='space-between' align='middle' gutter={8}>
            <Col flex='1 0 15%'>
              <Card style={{ marginBottom: '10px' }}>
                <b>{t('invoice.totalExpensesPaid')}</b>
                <div>{formatMoney(data?.totalExpensesPaid, 'RON')}</div>
              </Card>
            </Col>
            <Col flex='1 0 15%'>
              <Card style={{ marginBottom: '10px' }}>
                <b>{t('invoice.totalExpensesUnpaid')}</b>
                <div>{formatMoney(data?.totalExpensesUnpaid, 'RON')}</div>
              </Card>
            </Col>
            <Col flex='1 0 15%'>
              <Card style={{ marginBottom: '10px' }}>
                <b>{t('invoice.numberOfPaid')}</b>
                <div>{formatNumber(data?.nrOfPaid)}</div>
              </Card>
            </Col>
            <Col flex='1 0 15%'>
              <Card style={{ marginBottom: '10px' }}>
                <b>{t('invoice.numberOfUnpaid')}</b>
                <div>{formatNumber(data?.nrOfUnpaid)}</div>
              </Card>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Spin>
  );
}

export default InvoiceStats;
