import { useCallback } from 'react';
import { useFetch } from 'use-http';
import config from '../../config';

const { api } = config;
const { baseUrl, timesheetDeletePath } = api;

interface UseTimeSheetsType {
  deleteTimesheet: (id: string) => Promise<void>;
  loadingDelete: boolean;
}

export default function useModifyTimesheet(): UseTimeSheetsType {
  const { delete: remove, loading: loadingDelete } = useFetch(baseUrl);

  const deleteTimesheet = useCallback(
    async (id: string) => {
      try {
        await remove(`${timesheetDeletePath}`.replace(':id', id));
      } catch (ex) {
        console.log(ex);
      }
    },
    [remove],
  );

  return {
    deleteTimesheet,
    loadingDelete,
  };
}
