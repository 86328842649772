import { useCallback } from 'react';
import { useFetch } from 'use-http';
import config from '../../../config';

const { api } = config;
const { baseUrl, registerPath } = api;

interface RegisterType {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

interface RegisterHookType {
  register: (data: RegisterType) => Promise<void>;
  loading: boolean;
  error: Error | undefined;
  response: Response;
  data: Record<string, any>;
}

export function useRegister(): RegisterHookType {
  const { post, loading, error, response, data } = useFetch(`${baseUrl}${registerPath}`);

  const register = useCallback(
    async ({ firstName, lastName, email, password }: RegisterType) => {
      try {
        return await post({
          firstName,
          lastName,
          email,
          password,
        });
      } catch (e) {}
    },
    [post],
  );

  return {
    register,
    error,
    loading,
    response,
    data,
  };
}
