import React, { useMemo } from 'react';
import { Card, Col, Row, Statistic, Space } from 'antd';
import { AxisOptions, Chart } from 'react-charts';
import dayjs from 'dayjs';

import { useTimesheetListingDash } from '../contexts/TimesheetListingDashContext';

import { ChartData, StatsPerProject } from '../../timesheet/timesheet-types';
import ChartWrapper from './ChartWrapper';
import { formatNumber } from '../../../../utils/formatNumber';
import { formatMoney } from '../../../../utils/formatMoney';

const MONTHS_COUNT = 4;
function ChartTimesheet(): React.ReactElement {
  const { projectStats, loadingStats, filterOptions } = useTimesheetListingDash();
  const { from, to } = filterOptions;

  const defaultFromString: string = dayjs(new Date())
    .startOf('month')
    .subtract(MONTHS_COUNT, 'months')
    .format('YYYY-MM-DD');

  const defaultToString: string = dayjs(new Date()).endOf('month').format('YYYY-MM-DD');

  const chartData = useMemo(() => {
    return [
      {
        label: 'Hours',
        data: Object.entries(projectStats ?? {})
          .filter(([key]) => key !== 'allProjects')
          .map((projectArray: [string, StatsPerProject]): { project: string; value: number } => ({
            project: projectArray[0] === 'allProjects' ? 'Total projects' : projectArray[0],
            value: projectArray[1].totalHours,
          })),
      },
      {
        label: 'Contributors',
        data: Object.entries(projectStats ?? {})
          .filter(([key]) => key !== 'allProjects')
          .map((projectArray: [string, StatsPerProject]) => ({
            project: projectArray[0] === 'allProjects' ? 'Total projects' : projectArray[0],
            value:
              typeof projectArray[1].nrContributors !== 'undefined'
                ? projectArray[1].nrContributors
                : 0,
          })),
      },
      {
        label: 'Cost (x 1K EUR)',
        data: Object.entries(projectStats ?? {})
          .filter(([key]) => key !== 'allProjects')
          .map((projectArray: [string, StatsPerProject]) => ({
            project: projectArray[0],
            value:
              typeof projectArray[1].totalCost !== 'undefined'
                ? projectArray[1].totalCost / 1000
                : 0,
          })),
      },
    ];
  }, [projectStats]);

  const primaryAxis = React.useMemo(
    (): AxisOptions<ChartData[number]['data'][number]> => ({
      getValue: (data) => data.project,
      scaleType: 'band',
    }),
    [],
  );

  const secondaryAxes = React.useMemo(
    (): Array<AxisOptions<ChartData[number]['data'][number]>> => [
      {
        getValue: (data) => data.value,
        elementType: 'bar',
        scaleType: 'linear',
        min: 0,
      },
    ],
    [],
  );

  return (
    <Card>
      <Row justify='center'>
        <Col>
          <Space size={'large'}>
            <Statistic
              title='Total hours'
              value={formatNumber(projectStats?.allProjects?.totalHours)}
            />
            <Statistic
              title='Total cost'
              value={formatMoney(projectStats?.allProjects?.totalCost)}
            />
            <Statistic
              title='From'
              value={
                typeof from === 'undefined' || from === null
                  ? defaultFromString
                  : from?.format('YYYY-MM-DD')
              }
            />
            <Statistic
              title='To'
              value={
                typeof to === 'undefined' || to === null
                  ? defaultToString
                  : to?.format('YYYY-MM-DD')
              }
            />
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ChartWrapper>
            {!loadingStats && (
              <Chart
                options={{
                  data: chartData,
                  primaryAxis,
                  secondaryAxes,
                }}
              />
            )}
          </ChartWrapper>
        </Col>
      </Row>
    </Card>
  );
}

export default ChartTimesheet;
