import { Tag, Table, Space, Row, Popconfirm, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import { format } from 'date-fns';

import { useModifyUsers } from '../../../../hooks/users/useModifyUsers';
import { useUserListing } from '../contexts/UserListingContext';
import { useUserEmailFilterContext } from '../../../../general-purpose-contexts/UserEmailFilterContext';

import { UserStatus } from '../user.status';

import config from '../../../../config';

export interface UserType {
  id?: number;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  createdAt: string;
  status: number;
}

function UserDataTable(): React.ReactElement {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { deleteUsers, loadingDelete } = useModifyUsers();

  const { getEmails } = useUserEmailFilterContext();
  const { filterOptions, pagination, setPagination } = useUserListing();
  const { email, lastName, status } = filterOptions;
  const { page, pageSize } = pagination;

  const url = useMemo(() => {
    let path = `${config.api.baseUrl}${config.api.usersPath}?`;

    if (typeof email !== 'undefined') {
      path += `filter.email=$eq:${email}&`;
    }
    if (typeof lastName !== 'undefined' && lastName !== '') {
      path += `filter.lastName=$eq:${lastName}&`;
    }
    if (typeof status !== 'undefined') {
      path += `filter.status=$eq:${status}&`;
    }
    path += `page=${page}&limit=${pageSize}`;
    return path;
  }, [email, lastName, status, page, pageSize]);

  const {
    data,
    error,
    loading,
    get: getUsers,
  } = useFetch(
    url,
    {
      data: {},
    },
    [url],
  );

  const confirmDelete = async (id: string): Promise<void> => {
    const newPage = (data?.meta?.totalItems - 1) % data?.meta?.itemsPerPage === 0 ? page - 1 : page;
    await deleteUsers(id);
    setPagination({ page: newPage, pageSize });
    if (newPage === page) {
      await getUsers();
    }
    getEmails();
  };

  const columns: ColumnsType<UserType> = [
    {
      title: t('users.firstNameLabel'),
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: t('users.lastNameLabel'),
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: t('users.mail'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('users.role'),
      dataIndex: 'role',
      key: 'role',
      render: (role: string) => {
        return role.split('_').join(' ');
      },
    },
    {
      title: t('users.created'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_: unknown, { createdAt }: { createdAt: string }) => {
        return format(new Date(createdAt), 'dd/MM/yyyy');
      },
    },
    {
      title: t('users.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_: unknown, { status }: { status: number }) => {
        let color = 'red';
        let statusText = t('users.inactive');
        if (status === UserStatus.ACTIVE) {
          color = 'green';
          statusText = t('users.active');
        }
        if (status === UserStatus.PENDING) {
          color = 'yellow';
          statusText = t('users.pending');
        }
        return (
          <Tag color={color} key={status}>
            {statusText}
          </Tag>
        );
      },
    },

    {
      title: t('users.actions'),
      render: ({ id }: { id: number }) => {
        return (
          <Row>
            <Space>
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  navigate(config.routes.usersEdit.replace(':id', `${id}`));
                }}
              ></Button>
              <Popconfirm
                title={t('users.deleteUser')}
                description={t('users.deleteUserConfirmMessage')}
                okText={t('users.yes')}
                cancelText={t('users.no')}
                onConfirm={() => {
                  confirmDelete(`${id}`);
                }}
              >
                <Button icon={<DeleteOutlined />} danger></Button>
              </Popconfirm>
            </Space>
          </Row>
        );
      },
    },
  ];

  return (
    <Table
      rowKey='id'
      columns={columns}
      dataSource={error !== undefined || !Array.isArray(data?.data) ? [] : data?.data}
      loading={loading || loadingDelete}
      bordered
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '15', '20', '25', '30'],
        current: page,
        total: typeof data?.meta?.totalItems !== 'undefined' ? data?.meta?.totalItems : 0,
        position: ['bottomCenter'],
        pageSize,
        onChange: (page, pageSize) => {
          localStorage.setItem('pageSize', `${pageSize}`);
          setPagination({ page, pageSize });
        },
      }}
    ></Table>
  );
}

export default UserDataTable;
