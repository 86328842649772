import React, { useCallback, useEffect } from 'react';
import { Button, Col, Input, Row, Space, Typography, Upload, message, notification } from 'antd';
import { Form } from 'antd/lib';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { useFetch } from 'use-http';
import type { UploadProps } from 'antd';

import Page from '../../common/Page';
import { useTranslation } from 'react-i18next';
import config from '../../../config';
import { Link } from 'react-router-dom';
import { useInvoiceAdd } from './useInvoiceAdd';

const { Title } = Typography;
const { api } = config;
const { baseUrl, invoicesUploadPath, usersMe } = api;

function getBearer(): string {
  const token = localStorage.getItem('token');
  const tokenObj = JSON.parse(token ?? '{}');
  return tokenObj.bearer;
}

const AddInvoice: React.FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation('common');
  const { createInvoice, loading } = useInvoiceAdd();
  const { get } = useFetch(`${baseUrl}`);

  const loadData = useCallback(async () => {
    try {
      form.setFieldsValue(await get(usersMe));
    } catch (ex) {
      notification.error({
        message: t('common.error'),
        description: t('user.getUserFailed'),
        placement: 'topLeft',
      });
    }
  }, [form, get, t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    action: baseUrl + invoicesUploadPath,
    headers: {
      authorization: `Bearer ` + getBearer(),
    },
    beforeUpload: (file) => {
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        message.error(file.name + ' ' + t('invoice.fileTypeError') ?? '');
      }
      return isPDF || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.file.status === 'done') {
        // info.file.response
        const r = info.file.response;
        if (r?.invoiceTotal !== undefined) {
          form.setFieldValue('sum', r?.invoiceTotal?.value?.amount);
        }
        if (r?.invoiceId !== undefined) {
          form.setFieldValue('invoiceNo', r?.invoiceId?.content);
        }
        if (r?.items?.values.length > 0) {
          form.setFieldValue('description', r?.items?.values[0]?.properties?.Description?.content);
        }
        message.success(t('common.uploadOk') ?? '');
      } else if (info.file.status === 'error') {
        message.error(t('common.uploadFail') ?? '');
      }
    },
  };

  return (
    <Page>
      <Form name='invoices-add' form={form} layout='vertical' onFinish={createInvoice}>
        <Space direction='vertical' className='w-100'>
          <Title level={3}>{t('invoice.addInvoice')}</Title>
          <Row>
            <Col xs={24} md={16} lg={10}>
              <Form.Item
                name='file'
                label={t('invoice.file') ?? ''}
                rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
              >
                <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>{t('invoice.fileButton') ?? ''}</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={16} lg={10}>
              <Form.Item
                name='bankAccount'
                label={t('invoice.bankAccount') ?? ''}
                rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={16} lg={10}>
              <Form.Item
                name='companyName'
                label={t('invoice.companyName') ?? ''}
                rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={16} lg={10}>
              <Form.Item
                name='invoiceNo'
                label={t('invoice.invoiceNo') ?? ''}
                rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={16} lg={10}>
              <Form.Item
                name='sum'
                label={t('invoice.sum') ?? ''}
                rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
              >
                <Input type='text' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={16} lg={10}>
              <Form.Item
                name='description'
                label={t('invoice.description') ?? ''}
                rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Space size='small'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
                loading={loading}
                disabled={loading}
              >
                {t('invoice.save')}
              </Button>
              <Link to={config.routes.invoices}>{t('common.back') ?? ''}</Link>
            </Space>
          </Row>
        </Space>
      </Form>
    </Page>
  );
};

export default AddInvoice;
