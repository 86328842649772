import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Checkbox, Col, DatePicker, Input, Row, Select, Space, Typography } from 'antd';
import { Form } from 'antd/lib';
import { SaveOutlined } from '@ant-design/icons';

import { useFetch } from 'use-http';

import dayjs from 'dayjs';

import Page from '../../common/Page';
import { useTimesheetAdd } from './useTimesheetAdd';

import config from '../../../config';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const { Title } = Typography;
const { api } = config;
const { baseUrl, projectsActivePath } = api;

const AddTimesheet: React.FC = () => {
  const [form] = Form.useForm();
  const checkboxRef = useRef<boolean>(false);
  const { t } = useTranslation('common');

  const { data = [] } = useFetch(`${baseUrl}${projectsActivePath}`, {}, []);
  const { createTimesheet, loading } = useTimesheetAdd(checkboxRef.current, form);

  const handleCheckbox = (e: CheckboxChangeEvent): void => {
    checkboxRef.current = e.target.checked;
  };

  const projectOptions = useMemo(() => {
    if (Array.isArray(data)) {
      return data.map((item: { id: number; name: string }) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    }
    return [];
  }, [data]);

  return (
    <Page>
      <Form
        name='timesheet-add'
        form={form}
        layout='vertical'
        onFinish={(formValues) => {
          createTimesheet(formValues);
        }}
        initialValues={{ timesheetFrom: dayjs(), timesheetTo: dayjs() }}
      >
        <Title level={3}>{t('timesheet.timesheet')}</Title>
        <Row gutter={12}>
          <Col xs={24} md={16} lg={12}>
            <Form.Item
              name={'project'}
              label={t('timesheet.project') ?? ''}
              rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
            >
              <Select
                options={projectOptions}
                showSearch
                className={'w-100'}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={16} lg={12}>
            <Form.Item
              name={'taskDescription'}
              label={t('invoice.taskDescription') ?? ''}
              rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={16} lg={12}>
            <Form.Item
              name={'hours'}
              label={t('invoice.hours') ?? ''}
              rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
            >
              <Input type='number' min={0.25} step={0.01} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={16} lg={12}>
            <Form.Item
              name={'timesheetFrom'}
              label={t('timesheet.timesheetFrom') ?? ''}
              rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={16} lg={12}>
            <Form.Item
              name={'timesheetTo'}
              label={t('timesheet.timesheetTo') ?? ''}
              rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Space direction='horizontal' size='large'>
          <Checkbox onChange={handleCheckbox}>{t('timesheet.addAnother')}</Checkbox>
          <Space size='middle'>
            <Button
              type='primary'
              icon={<SaveOutlined />}
              htmlType='submit'
              loading={loading}
              disabled={loading}
            >
              {t('timesheet.save')}
            </Button>
            <Link to={config.routes.timesheet}>{t('common.back') ?? ''}</Link>
          </Space>
        </Space>
      </Form>
    </Page>
  );
};

export default AddTimesheet;
