import React from 'react';
import { Provider } from 'use-http';
import { Route, Routes } from 'react-router-dom';

import config from '../config';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import PrivateRoute from './common/PrivateRoute';
import Invoices from './pages/invoices/Invoices';
import AddInvoice from './pages/invoices/AddInvoice';
import Project from './pages/project/Project';
import AddProject from './pages/project/AddProject';
import ChangePassword from './pages/password/ChangePassword';
import { UserRole } from './pages/users/user.role';
import Users from './pages/users/Users';
import UsersEdit from './pages/users/UsersEdit';
import NotFound from './common/NotFound';
import { useApp } from './useApp';
import ForgotPassword from './pages/forgot/ForgotPassword';
import ForgotNewPassword from './pages/forgot/ForgotNewPassword';
import ResetPassword from './pages/forgot/ResetPassword';
import ProfileEdit from './pages/profile/ProfileEdit';
import Timesheet from './pages/timesheet/Timesheet';
import AddTimesheet from './pages/timesheet/AddTimesheet';
import ProjectEdit from './pages/project/ProjectEdit';
import Dashboard from './pages/dashboard/Dashboard';
import TimesheetEdit from './pages/timesheet/TimesheetEdit';
import Auth from './Auth';

export const Pages: React.FC = () => {
  const { options } = useApp();

  return (
    <Provider options={options}>
      <Routes>
        <Route element={<Auth allowedRoles={[UserRole.ADMIN]} redirect={config.routes.invoices} />}>
          <Route
            path={config.routes.home}
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </Route>
        <Route element={<Auth allowedRoles={[UserRole.ADMIN]} redirect={config.routes.invoices} />}>
          <Route
            path={config.routes.dashboard}
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path={config.routes.login} element={<Login />} />
        <Route path={config.routes.register} element={<Register />} />
        <Route path={config.routes.forgot} element={<ForgotPassword />} />
        <Route path={config.routes.forgotNew} element={<ForgotNewPassword />} />
        <Route path={config.routes.reset} element={<ResetPassword />} />
        <Route
          path={config.routes.invoices}
          element={
            <PrivateRoute>
              <Invoices />
            </PrivateRoute>
          }
        />
        <Route
          path={config.routes.timesheet}
          element={
            <PrivateRoute>
              <Timesheet />
            </PrivateRoute>
          }
        />
        <Route
          path={config.routes.timesheetEdit}
          element={
            <PrivateRoute>
              <TimesheetEdit />
            </PrivateRoute>
          }
        />
        <Route
          path={config.routes.invoicesAdd}
          element={
            <PrivateRoute>
              <AddInvoice />
            </PrivateRoute>
          }
        />
        <Route
          path={config.routes.timesheetAdd}
          element={
            <PrivateRoute>
              <AddTimesheet />
            </PrivateRoute>
          }
        />
        <Route path={config.routes.changePassword} element={<ChangePassword />} />
        <Route element={<Auth allowedRoles={[UserRole.ADMIN]} redirect={config.routes.invoices} />}>
          <Route
            path={config.routes.projects}
            element={
              <PrivateRoute>
                <Project />
              </PrivateRoute>
            }
          />
        </Route>
        <Route element={<Auth allowedRoles={[UserRole.ADMIN]} redirect={config.routes.invoices} />}>
          <Route
            path={config.routes.projectsAdd}
            element={
              <PrivateRoute>
                <AddProject />
              </PrivateRoute>
            }
          />
        </Route>
        <Route element={<Auth allowedRoles={[UserRole.ADMIN]} redirect={config.routes.invoices} />}>
          <Route
            path={config.routes.projectsEdit}
            element={
              <PrivateRoute>
                <ProjectEdit />
              </PrivateRoute>
            }
          />
        </Route>
        <Route element={<Auth allowedRoles={[UserRole.ADMIN]} redirect={config.routes.invoices} />}>
          <Route
            path={config.routes.users}
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
        </Route>
        <Route element={<Auth allowedRoles={[UserRole.ADMIN]} redirect={config.routes.invoices} />}>
          <Route
            path={config.routes.usersEdit}
            element={
              <PrivateRoute>
                <UsersEdit />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path={config.routes.profile}
          element={
            <PrivateRoute>
              <ProfileEdit />
            </PrivateRoute>
          }
        />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Provider>
  );
};
