import { useCallback, useEffect } from 'react';
import { useFetch } from 'use-http';
import { useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';

import config from '../../../config';
import { useLocalStorage } from 'usehooks-ts';
import { UserRole } from '../users/user.role';

interface LoginData {
  email: string;
  password: string;
}

interface LoginHook {
  login: (data: LoginData) => void;
  loading: boolean;
  error: Error | undefined;
}

const { api } = config;
const { baseUrl, loginPath } = api;

export default function useLogin(): LoginHook {
  const { post, loading, error, response } = useFetch(`${baseUrl}${loginPath}`);

  const [token, setToken] = useLocalStorage('token', { bearer: '' });
  const [user, setUser] = useLocalStorage('user', { email: '', role: '' });
  const [, setIsOnboarded] = useLocalStorage('is_onboarded', false);

  const navigate = useNavigate();

  useEffect(() => {
    if (response.ok && token.bearer !== '' && user.role !== '' && user.email !== '') {
      navigate(user.role === UserRole.ADMIN ? config.routes.dashboard : config.routes.invoices);
    }
  }, [navigate, response, user, token]);

  const login = useCallback(
    async (data: LoginData) => {
      try {
        const r = await post(data);
        setToken({ bearer: r.access_token });
        const decoded: Record<string, string> = jwt(r.access_token);

        setIsOnboarded(r.is_onboarded);
        setUser({ email: decoded.email, role: decoded.role });
      } catch {
        console.log('Invalid credentials -> useLogin');
      }
    },
    [post, setToken, setIsOnboarded, setUser],
  );

  return {
    login,
    loading,
    error,
  };
}
