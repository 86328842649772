import { useCallback, useEffect } from 'react';
import { useFetch } from 'use-http';
import { notification } from 'antd';

import config from '../../config';
import { useTranslation } from 'react-i18next';

interface ModifyUsersHookType {
  loadingDelete: boolean;
  deleteUsers: (id: string) => void;
}

export function useModifyUsers(): ModifyUsersHookType {
  const { t } = useTranslation('common');

  const {
    delete: remove,
    loading: loadingDelete,
    response: deleteResponse,
    error: deleteError,
  } = useFetch(`${config.api.baseUrl}`);

  useEffect(() => {
    if (deleteResponse.ok) {
      notification.success({
        message: t('common.success'),
        description: t('users.deleteUsersSuccessfully'),
        placement: 'topLeft',
      });
    }
  }, [deleteResponse.ok, t]);

  useEffect(() => {
    if (deleteError != null) {
      notification.error({
        message: t('common.error'),
        description: t('users.deleteUsersFailed'),
        placement: 'topLeft',
      });
    }
  }, [deleteError, t]);

  const deleteUsers = useCallback(
    async (id: string) => {
      try {
        await remove(`${config.api.usersDeletePath}`.replace(':id', id));
      } catch (ex) {
        console.log(ex);
      }
    },
    [remove],
  );

  return {
    deleteUsers,
    loadingDelete,
  };
}
