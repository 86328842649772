import React, { ReactElement, ReactNode, useContext, useMemo } from 'react';
import { useEmailFilter } from '../hooks/users/useEmailFilter';
import { DefaultOptionType } from 'antd/es/select';

interface UserEmailFilterType {
  options: DefaultOptionType[];
  getEmails: () => void;
}

const defaultValue = {
  options: [],
  getEmails: async () => {},
};
export const UserEmailFilterContext = React.createContext<UserEmailFilterType>(defaultValue);

export const UserEmailFilterProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const { options, getEmails } = useEmailFilter();

  const value = useMemo(() => {
    return {
      options,
      getEmails,
    };
  }, [getEmails, options]);

  return (
    <UserEmailFilterContext.Provider value={value}>{children}</UserEmailFilterContext.Provider>
  );
};

export const useUserEmailFilterContext = (): UserEmailFilterType => {
  return useContext(UserEmailFilterContext);
};
