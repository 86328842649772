import React, { ReactElement, ReactNode, useContext, useMemo, useState } from 'react';
import useFetch from 'use-http';

import {
  FetchedProjectType,
  FilterOptions,
  PaginationOptions,
  ResponseMetaType,
} from '../project-types';

import config from '../../../../config';
import { getPageSize } from '../../../common/DataTableDefaults';

interface ProjectListingType {
  filterOptions: FilterOptions;
  setFilterOptions: (filters: FilterOptions) => void;
  pagination: PaginationOptions;
  setPagination: (pagOptions: PaginationOptions) => void;
  data: { data: FetchedProjectType[]; meta: ResponseMetaType; links: Record<string, string> };
  loading: boolean;
  error: {} | undefined;
  getProjects: () => void;
}

const defaultValue: ProjectListingType = {
  filterOptions: {
    id: undefined,
    status: undefined,
    type: undefined,
  },
  setFilterOptions: (filters: FilterOptions): void => {},
  pagination: {
    page: 1,
    pageSize: getPageSize(),
  },
  setPagination: (pagination: PaginationOptions): void => {},
  data: {
    data: [{ id: 0, code: '', name: '', status: 0, type: 0 }],
    meta: { currentPage: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0, sortBy: [] },
    links: {},
  },
  loading: false,
  error: undefined,
  getProjects: (): void => {},
};

export const ProjectListingContext = React.createContext<ProjectListingType>(defaultValue);

export const ProjectListingProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    id: undefined,
    status: undefined,
    type: undefined,
  });

  const [pagination, setPagination] = useState<PaginationOptions>({
    page: 1,
    pageSize: getPageSize(),
  });

  const { id, status, type } = filterOptions;
  const { page, pageSize } = pagination;

  const url = useMemo(() => {
    let path = `${config.api.baseUrl}${config.api.projectsWithManagers}?`;

    if (typeof id !== 'undefined') {
      path += `filter.id=$eq:${id}&`;
    }
    if (typeof type !== 'undefined') {
      path += `filter.type=$eq:${type}&`;
    }
    if (typeof status !== 'undefined') {
      path += `filter.status=$eq:${status}&`;
    }

    path += `page=${page}&limit=${pageSize}`;

    return path;
  }, [id, type, status, page, pageSize]);

  const { data, error, loading, get: getProjects } = useFetch(url, { data: {} }, [url]);

  const values = useMemo(() => {
    return {
      data,
      error,
      loading,
      getProjects,
      filterOptions,
      setFilterOptions,
      pagination,
      setPagination,
    };
  }, [
    data,
    error,
    loading,
    getProjects,
    filterOptions,
    setFilterOptions,
    pagination,
    setPagination,
  ]);

  return <ProjectListingContext.Provider value={values}>{children}</ProjectListingContext.Provider>;
};

export const useProjectListing = (): ProjectListingType => {
  return useContext(ProjectListingContext);
};
