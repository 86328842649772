import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Typography } from 'antd';

import InvoiceFilters from './subcomponents/InvoiceFilters';
import DownloadInvoicesButtons from './subcomponents/DownloadInvoicesButtons';
import InvoiceDataTable from './subcomponents/InvoiceDataTable';
import { UserEmailFilterProvider } from '../../../general-purpose-contexts/UserEmailFilterContext';
import { InvoiceListingProvider } from './contexts/InvoiceListingContext';

import Page from '../../common/Page';
import { UserRole } from '../users/user.role';
import { PlusOutlined } from '@ant-design/icons';
import config from '../../../config';
import { useUser } from '../users/UserContext';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const Invoices: React.FC = () => {
  const { t } = useTranslation('common');
  const { user } = useUser();
  const { role } = user;
  const navigate = useNavigate();

  return (
    <Page>
      <UserEmailFilterProvider>
        <InvoiceListingProvider>
          <Space direction='vertical' size='middle' className='w-100'>
            <Row>
              <Col xs={24} sm={12} md={6}>
                <Title level={3}>{t('invoice.title')}</Title>
              </Col>
              <Col xs={24} sm={12} md={18}>
                <Row justify='end'>
                  {role === UserRole.COLLABORATOR || role === UserRole.PM ? (
                    <Button
                      icon={<PlusOutlined />}
                      type='primary'
                      onClick={() => {
                        navigate(config.routes.invoicesAdd);
                      }}
                    >
                      {`${t('invoice.addInvoice')}`}
                    </Button>
                  ) : (
                    <></>
                  )}
                </Row>
              </Col>
            </Row>

            <InvoiceFilters />
            <DownloadInvoicesButtons />
            <InvoiceDataTable />
          </Space>
        </InvoiceListingProvider>
      </UserEmailFilterProvider>
    </Page>
  );
};

export default Invoices;
