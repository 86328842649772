export function downloadFileInBrowser(
  fileDownloadData: Blob,
  url: string,
  nameWithExtension: string,
): void {
  const file = new Blob([fileDownloadData], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const fileUrl = window.URL.createObjectURL(file);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = fileUrl;
  a.download = `${nameWithExtension}`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}
