import dayjs from 'dayjs';
import { FilterOptionsInvoice } from '../components/pages/invoices/invoice-types';
import { FilterOptionsTimesheet } from '../components/pages/timesheet/timesheet-types';

export const displaySearchParams = (
  formResponse: FilterOptionsInvoice | FilterOptionsTimesheet,
  setterFunction: (queryParams: URLSearchParams) => void,
): void => {
  const params = new URLSearchParams(
    Object.fromEntries(
      Object.entries(formResponse)
        .filter(([, value]) => value)
        .map((arr) =>
          arr[0] === 'from' || arr[0] === 'to' ? [arr[0], arr[1].toISOString()] : [arr[0], arr[1]],
        ),
    ),
  );
  setterFunction(params);
};

export const addPaginationQuery = (
  currentQueryParams: URLSearchParams,
  page: number,
  pageSize?: number,
): URLSearchParams => {
  currentQueryParams.set('page', page.toString());
  if (typeof pageSize !== 'undefined') {
    currentQueryParams.set('pageSize', pageSize.toString());
  }

  return currentQueryParams;
};

export const isValidPage = (pageString: string): boolean => {
  const pageNumber = parseInt(pageString);
  if (!isNaN(pageNumber)) {
    if (pageNumber === 0) {
      return false;
    }
    return true;
  }
  return false;
};

export const isValidDayjsString = (dayjsString: string): boolean => {
  return dayjs(dayjsString).isValid();
};

export const isValidStatus = (projectStatString: string): boolean => {
  const projectStatNumber = parseInt(projectStatString);
  if (!isNaN(projectStatNumber)) {
    return true;
  }
  return false;
};

export const isValidProjectId = (projectIdString: string): boolean => {
  const projectIdNumber = parseInt(projectIdString);
  if (!isNaN(projectIdNumber)) {
    return true;
  }
  return false;
};
