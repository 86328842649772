import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
  notification,
  DatePicker,
  InputNumber,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import { useForm } from 'antd/es/form/Form';

import Page from '../../common/Page';
import config from '../../../config';
import { UserStatus } from './user.status';
import { UserRole } from './user.role';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useProjectList } from '../project/useProjectList';
import dayjs from 'dayjs';

const { Title } = Typography;

const { api } = config;
const { baseUrl, usersEditPath } = api;

interface UpdateEdit {
  firstName: string;
  lastName: string;
  status: number;
  email: string;
  defaultCostPerHour: string;
  role: string;
  costs: Cost[];
}

interface Cost {
  projectId: number;
  costPerHour: number;
  from: string;
  to: string;
  description: string;
}

const UsersEdit: React.FC = () => {
  const { t } = useTranslation('common');
  const { id } = useParams();
  const navigate = useNavigate();

  const { get } = useFetch(`${baseUrl}`);
  const { put, error: errorSave, data: userData, loading } = useFetch(`${baseUrl}`);
  const [form] = useForm();

  const loadData = useCallback(async () => {
    try {
      const data = await get(`${usersEditPath}`.replace(':id', `${id}`));
      for (const item of data.costs) {
        item.from = dayjs(item.from, 'YYYY-MM-DD');
        item.to = dayjs(item.to, 'YYYY-MM-DD');
      }
      form.setFieldsValue(data);
    } catch (ex) {}
  }, [form, get, id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const statusOptions = [
    {
      value: UserStatus.ACTIVE,
      label: t('users.active'),
    },
    {
      value: UserStatus.PENDING,
      label: t('users.pending'),
    },
    {
      value: UserStatus.INACTIVE,
      label: t('users.inactive'),
    },
  ];

  const roleOptions = [
    {
      value: UserRole.ADMIN,
      label: t('users.admin'),
    },
    {
      value: UserRole.COLLABORATOR,
      label: t('users.collaborator'),
    },
    {
      value: UserRole.PM,
      label: t('users.pm'),
    },
  ];

  const { projectOptions } = useProjectList();

  const onFinish = useCallback(
    async (data: UpdateEdit) => {
      for (const cost of data.costs) {
        cost.from = dayjs(cost.from).format('YYYY-MM-DD');
        cost.to = dayjs(cost.to).format('YYYY-MM-DD');
        cost.costPerHour = parseFloat(cost.costPerHour.toString());
      }

      await put(`${usersEditPath}`.replace(':id', `${id}`), {
        firstName: data.firstName,
        lastName: data.lastName,
        status: data.status,
        email: data.email,
        defaultCostPerHour: parseFloat(data.defaultCostPerHour),
        costs: data.costs,
        role: data.role,
      });
    },
    [id, put],
  );

  useEffect(() => {
    if (!isNaN(userData?.id)) {
      notification.success({
        message: t('common.success'),
        description: t('users.userUpdatedSuccessfully'),
        placement: 'topLeft',
      });
      return navigate(config.routes.users);
    }

    if (errorSave !== undefined) {
      notification.error({
        message: t('common.error'),
        description: t('users.userUpdatedError'),
        placement: 'topLeft',
      });
    }
  }, [userData, errorSave, navigate, t]);

  return (
    <Page>
      <Title level={3}>{t('users.titleEdit') ?? ''}</Title>
      <Form
        name='users-form'
        className='users-form'
        initialValues={{ remember: false }}
        layout='vertical'
        form={form}
        onFinish={onFinish}
        disabled={loading}
      >
        <Row gutter={12}>
          <Col xl={6}>
            <Form.Item
              name='email'
              label={t('users.mail')}
              rules={[{ required: true, message: t('users.nameError') ?? '' }]}
            >
              <Input type='text' placeholder={t('users.namePlaceholder') ?? ''} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xl={6}>
            <Form.Item
              name='firstName'
              label={t('users.firstNameLabel')}
              rules={[{ required: true, message: t('users.firstNameError') ?? '' }]}
            >
              <Input type='text' placeholder={t('users.userPlaceholder') ?? ''} />
            </Form.Item>
          </Col>
          <Col xl={6}>
            <Form.Item
              name='lastName'
              label={t('users.lastNameLabel')}
              rules={[{ required: true, message: t('users.lastNameError') ?? '' }]}
            >
              <Input type='text' placeholder={t('users.lastUserPlaceholder') ?? ''} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xl={4}>
            <Form.Item name='status' label={t('users.status')}>
              <Select
                placeholder={t('users.listStatusPlaceholder') ?? ''}
                options={statusOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xl={4}>
            <Form.Item name='role' label={t('users.role')}>
              <Select placeholder={t('users.listRolePlaceholder') ?? ''} options={roleOptions} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Item
              name={'defaultCostPerHour'}
              label={t('users.defaultCostPerHour')}
              rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
            >
              <Input type='number' />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Title level={5}>{t('cost.titleExceptions') ?? ''}</Title>
            <Form.List name='costs'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex' }} align='center'>
                      <Form.Item
                        {...restField}
                        name={[name, 'projectId']}
                        label={t('cost.project')}
                        rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
                      >
                        <Select
                          showSearch
                          placeholder={t('cost.listProjectPlaceholder') ?? ''}
                          options={projectOptions}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        label={t('cost.costPerHour') ?? ''}
                        name={[name, 'costPerHour']}
                        rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
                      >
                        <InputNumber />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={t('cost.from') ?? ''}
                        name={[name, 'from']}
                        rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
                      >
                        <DatePicker />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={t('cost.to') ?? ''}
                        name={[name, 'to']}
                        rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
                      >
                        <DatePicker />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'description']}
                        label={t('cost.description')}
                        rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
                      >
                        <Input placeholder={t('cost.description') ?? ''} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                      {t('cost.btnAddCostException') ?? ''}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Form.Item>
          <Space size='small'>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
              disabled={loading}
              loading={loading}
            >
              {t('common.update') ?? ''}
            </Button>
            <Link to={config.routes.users}>{t('common.back') ?? ''}</Link>
          </Space>
        </Form.Item>
      </Form>
    </Page>
  );
};

export default UsersEdit;
