import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, DatePicker, Form, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';

import { useUserEmailFilterContext } from '../../../../general-purpose-contexts/UserEmailFilterContext';
import { useTimesheetListingDash } from '../contexts/TimesheetListingDashContext';
import { useUser } from '../../users/UserContext';
import { useProjectList } from '../../project/useProjectList';
import { UserRole } from '../../users/user.role';
import { rangePresets } from '../../../common/RangePresets';

const { RangePicker } = DatePicker;

const MONTHS_COUNT = 4;

function TimesheetFilters(): React.ReactElement {
  const { t } = useTranslation('common');
  const [form] = Form.useForm();

  const { options } = useUserEmailFilterContext();

  const { setFilterOptions, setPagination } = useTimesheetListingDash();
  const {
    user: { role },
  } = useUser();
  const { projectOptions } = useProjectList();

  const handleFilteringOptions = (operation: string): void => {
    if (operation === 'filter') {
      const currentFilters = form.getFieldsValue(['period', 'project', 'userEmail']);
      currentFilters.from =
        currentFilters.period != null && currentFilters.period.length === 2
          ? currentFilters.period[0]
          : undefined;
      currentFilters.to =
        currentFilters.period != null && currentFilters.period.length === 2
          ? currentFilters.period[1]
          : undefined;

      setFilterOptions(currentFilters);
    } else if (operation === 'clear') {
      setFilterOptions({
        project: undefined,
        from: dayjs(new Date()).startOf('month').subtract(MONTHS_COUNT, 'months'),
        to: dayjs(new Date()).endOf('month'),
        userEmail: undefined,
        period: [
          dayjs(new Date()).startOf('month').subtract(MONTHS_COUNT, 'months'),
          dayjs(new Date()).endOf('month'),
        ],
      });
    }
    setPagination({ page: 1, pageSize: 0 });
  };

  return (
    <Card>
      <Form
        name='filter-dashboard'
        form={form}
        layout='vertical'
        wrapperCol={{ span: 20, offset: 0 }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item name='period' label={t('rangePicker.label') ?? ''}>
              <RangePicker presets={rangePresets} className='w-100' />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item name={'project'} label={t('timesheet.project') ?? ''}>
              <Select
                style={{ width: '100%' }}
                options={projectOptions}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              ></Select>
            </Form.Item>
          </Col>

          {role === UserRole.ADMIN ? (
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name='userEmail'
                label={t('invoice.userEmail') ?? ''}
                rules={[{ type: 'email' }]}
              >
                <Select style={{ width: '100%' }} allowClear showSearch options={options} />
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <Row justify={'end'}>
          <Col>
            <Button
              htmlType='button'
              icon={<SearchOutlined />}
              onClick={() => {
                handleFilteringOptions('filter');
              }}
            >
              {t('common.filterTitle') ?? ''}
            </Button>
            <Button
              htmlType='reset'
              type='text'
              onClick={() => {
                form.resetFields();
                handleFilteringOptions('clear');
              }}
            >
              {t('common.clearBtn') ?? ''}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default TimesheetFilters;
