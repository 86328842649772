import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Col, Row, Space, Typography } from 'antd';

import { ProjectListingProvider } from './contexts/ProjectListingContext';

import Page from '../../common/Page';
import ProjectDataTable from './subcomponents/ProjectDataTable';
import ProjectFilters from './subcomponents/ProjectFilters';

import config from '../../../config';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;

const Project: React.FC = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <Page>
      <ProjectListingProvider>
        <Space direction='vertical' size='middle' className='w-100'>
          <Row>
            <Col xs={24} sm={12} md={6}>
              <Title level={3}>{t('project.listTitle')}</Title>
            </Col>
            <Col xs={24} sm={12} md={18}>
              <Row justify='end'>
                <Button
                  type={'primary'}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    navigate(config.routes.projectsAdd);
                  }}
                >
                  {`${t('common.addBtn')}`}
                </Button>
              </Row>
            </Col>
          </Row>
          <ProjectFilters />
          <ProjectDataTable />
        </Space>
      </ProjectListingProvider>
    </Page>
  );
};

export default Project;
