import React from 'react';
import { ResizableBox as ReactResizableBox } from 'react-resizable';

import 'react-resizable/css/styles.css';

interface ResizeType {
  children: React.ReactNode;
  width: number;
  height: number;
  resizable: boolean;
  style: {};
  className: '';
}
const ChartWrapper: React.ElementType = ({
  children,
  width = 100,
  height = 500,
  resizable = false,
  style = {},
  className = '',
}: ResizeType) => {
  return (
    <div style={{ marginLeft: 20 }}>
      <div
        style={{
          display: 'inline-block',
          width: '100%',
          background: '#fff',
          padding: '.5rem',
          borderRadius: '0.5rem',
          boxShadow: '0 30px 40px rgba(0,0,0,.1)',
          ...style,
        }}
      >
        {resizable ? (
          <ReactResizableBox width={width} height={height}>
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
              }}
              className={className}
            >
              {children}
            </div>
          </ReactResizableBox>
        ) : (
          <div
            style={{
              width: `${width}%`,
              minHeight: `${height}px`,
            }}
            className={className}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChartWrapper;
