import { Space, Typography } from 'antd';

import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../../common/Page';

import UserFilters from '../users/subcomponents/UserFilters';
import UserDataTable from './subcomponents/UserDataTable';
import { UserEmailFilterProvider } from '../../../general-purpose-contexts/UserEmailFilterContext';
import { UserListingProvider } from './contexts/UserListingContext';

function Users(): React.ReactElement {
  const { Title } = Typography;
  const { t } = useTranslation('common');
  return (
    <Page>
      <UserEmailFilterProvider>
        <UserListingProvider>
          <Title level={3}>{t('users.title')}</Title>
          <Space className='w-100' direction='vertical' size='large'>
            <UserFilters />
            <UserDataTable />
          </Space>
        </UserListingProvider>
      </UserEmailFilterProvider>
    </Page>
  );
}

export default Users;
