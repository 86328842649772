import React, { useEffect } from 'react';
import { Button, Card, Checkbox, Col, Form, Input, Row, Space, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import s from '../../App.module.scss';

import config from '../../../config';
import { useRegister } from './useRegister';
import LogoBlackSvg from '../../../assets/logo-black.svg';

const Register: React.FC = () => {
  const { t } = useTranslation('common');

  const [form] = Form.useForm();
  const { register, loading, error, data } = useRegister();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isNaN(data?.created)) {
      notification.success({
        message: 'Success',
        description: t('register.registerComplete'),
        placement: 'topLeft',
      });
      navigate(config.routes.login);
    }
  }, [data, navigate, t]);

  useEffect(() => {
    if (error != null) {
      notification.error({
        message: 'Error',
        description: t('register.registerFailed'),
        placement: 'topLeft',
      });
    }
  }, [error, t]);

  return (
    <Row justify='center' align='middle' className={s.bgGradient} style={{ height: '100vh' }}>
      <div className={s.curve} />
      <Card className={s.cardShadow}>
        <div className={s.logoContainer}>
          <img src={LogoBlackSvg} />
        </div>
        <Col sm={24} lg={24}>
          <Form
            form={form}
            name='register'
            scrollToFirstError
            layout='vertical'
            onFinish={register}
          >
            <Row>
              <Col xs={24} lg={16}>
                <Form.Item
                  name='email'
                  label={t('register.emailLabel') ?? ''}
                  rules={[
                    {
                      type: 'email',
                      message: t('register.emailError') ?? '',
                    },
                    {
                      required: true,
                      message: t('register.emailErrorEmpty') ?? '',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col xs={24} lg={11}>
                <Form.Item
                  name='firstName'
                  label={t('register.firstNameLabel') ?? ''}
                  rules={[
                    {
                      required: true,
                      message: t('register.firstNameErrorEmpty') ?? '',
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} lg={11}>
                <Form.Item
                  name='lastName'
                  label={t('register.lastNameLabel') ?? ''}
                  rules={[
                    {
                      required: true,
                      message: t('register.lastNameErrorEmpty') ?? '',
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col xs={24} lg={11}>
                <Form.Item
                  name='password'
                  label={t('register.passLabel') ?? ''}
                  rules={[
                    {
                      required: true,
                      message: t('register.passErrorEmpty') ?? '',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col xs={24} lg={11}>
                <Form.Item
                  name='confirm'
                  label={t('register.confirmPassLabel') ?? ''}
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t('register.confirmPassErrorEmpty') ?? '',
                    },
                    ({ getFieldValue }) => ({
                      async validator(_, value) {
                        if (
                          value === undefined ||
                          value === '' ||
                          getFieldValue('password') === value
                        ) {
                          return await Promise.resolve();
                        }
                        throw new Error(t('register.confirmPassError') ?? '');
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name='agreement'
              valuePropName='checked'
              rules={[
                {
                  validator: async (_, value) => {
                    return value === undefined || value === '' || value === 0 || value === false
                      ? await Promise.reject(new Error(t('register.agreementError') ?? ''))
                      : await Promise.resolve();
                  },
                },
              ]}
            >
              <Checkbox>
                {t('register.read')} <Link to={config.routes.home}>{t('register.agreement')}</Link>
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Space>
                <Button type='primary' htmlType='submit' disabled={loading} loading={loading}>
                  {t('register.register')}
                </Button>
                {t('register.orText')} <Link to={config.routes.login}>{t('register.login')}</Link>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Card>
    </Row>
  );
};

export default Register;
