import React, { ReactElement, ReactNode, useContext, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import jwt from 'jwt-decode';
import dayjs from 'dayjs';
import { UserRole } from './user.role';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';

interface JWTPayload {
  sub: string;
  name: string;
  email: string;
  role: string;
  iat: number;
  exp: number;
}

interface DecodedUser {
  email: string;
  role: string;
}

interface UserType {
  user: DecodedUser;
}

const defaultValue: UserType = {
  user: { email: '', role: '' },
};
export const UserContext = React.createContext<UserType>(defaultValue);

export const UserProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const navigate = useNavigate();

  const [isOnboarded] = useLocalStorage('is_onboarded', false);
  const [token] = useLocalStorage('token', { bearer: '' });
  const [user] = useLocalStorage('user', { email: '', role: '' });

  useEffect(() => {
    try {
      if (token.bearer !== '' && user.email !== '' && user.role !== '') {
        const decoded: JWTPayload = jwt<JWTPayload>(token.bearer);
        const isTokenExpired = dayjs(decoded.exp * 1000).isBefore(dayjs());

        if (
          !isOnboarded &&
          (user.role === UserRole.COLLABORATOR || user.role === UserRole.PM) &&
          !isTokenExpired
        ) {
          navigate(config.routes.profile);
        }
      }
    } catch {
      console.log('Invalid password -> UserContext');
      throw new Error();
    }
  }, [isOnboarded, navigate, user, token.bearer]);

  const value = {
    user,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = (): UserType => {
  return useContext(UserContext);
};
