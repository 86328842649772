import React from 'react';

import 'antd/dist/reset.css';
import { UserProvider } from './pages/users/UserContext';
import { Pages } from './Pages';

const App: React.FC = () => {
  return (
    <UserProvider>
      <Pages />
    </UserProvider>
  );
};

export default App;
