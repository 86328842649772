import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
  notification,
  DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import { useForm } from 'antd/es/form/Form';

import Page from '../../common/Page';
import config from '../../../config';

const { Title } = Typography;

const { api } = config;
const { baseUrl, timesheetEditPath, projectsActivePath } = api;

interface UpdateEdit {
  description: string;
  projectId: string;
  hours: string;
  timesheetFrom: dayjs.Dayjs;
  timesheetTo: dayjs.Dayjs;
}

const TimesheetEdit: React.FC = () => {
  const { t } = useTranslation('common');
  const { id } = useParams();
  const navigate = useNavigate();

  const { get } = useFetch(`${baseUrl}`);
  const { put, error: errorSave, data: timesheetData, loading } = useFetch(`${baseUrl}`);
  const [form] = useForm();

  const { data = [] } = useFetch(`${baseUrl}${projectsActivePath}`, {}, []);

  const projectOptions = useMemo(() => {
    if (Array.isArray(data)) {
      return data.map((item: { id: number; name: string }) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    }
    return [];
  }, [data]);

  const loadData = useCallback(async () => {
    try {
      get(`${timesheetEditPath}`.replace(':id', `${id}`)).then((data) => {
        form.setFieldsValue({
          timesheetFrom: dayjs(data.from, 'YYYY-MM-DD'),
          timesheetTo: dayjs(data.to, 'YYYY-MM-DD'),
          ...data,
        });
      });
    } catch (ex) {
      //
    }
  }, [form, get, id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onFinish = useCallback(
    async (data: UpdateEdit) => {
      await put(`${timesheetEditPath}`.replace(':id', `${id}`), {
        description: data.description,
        projectId: data.projectId,
        hours: parseFloat(data.hours),
        from: data.timesheetFrom.format('YYYY-MM-DD'),
        to: data.timesheetTo.format('YYYY-MM-DD'),
      });
    },
    [id, put],
  );

  useEffect(() => {
    if (!isNaN(timesheetData?.id)) {
      notification.success({
        message: t('common.success'),
        description: t('timesheet.updatedSuccessfully'),
        placement: 'topLeft',
      });
      return navigate(config.routes.timesheet);
    }
    if (errorSave !== undefined) {
      notification.error({
        message: t('common.error'),
        description: t('timesheet.updatedError'),
        placement: 'topLeft',
      });

      navigate(config.routes.timesheet);
    }
  }, [timesheetData, errorSave, navigate, t]);

  return (
    <Page>
      <Title level={3}>{t('timesheet.titleEdit') ?? ''}</Title>
      <Form
        name='timesheet-edit-form'
        className='timesheet-form'
        initialValues={{ remember: false }}
        layout='vertical'
        form={form}
        onFinish={onFinish}
        disabled={loading}
      >
        <Row gutter={12}>
          <Col xs={24} md={16} lg={12}>
            <Form.Item name='projectId' label={t('timesheet.project')}>
              <Select
                showSearch
                options={projectOptions}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} md={16} lg={12}>
            <Form.Item
              name='description'
              label={t('timesheet.taskDescription')}
              rules={[{ required: true, message: t('timesheet.taskDescriptionError') ?? '' }]}
            >
              <Input.TextArea placeholder={t('timesheet.taskDescription') ?? ''} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={16} lg={12}>
            <Form.Item
              name={'hours'}
              label={t('timesheet.hours') ?? ''}
              rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
            >
              <Input type='number' min={0.25} step={0.01} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={16} lg={12}>
            <Form.Item
              name={'timesheetFrom'}
              label={t('timesheet.timesheetFrom') ?? ''}
              rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={16} lg={12}>
            <Form.Item
              name={'timesheetTo'}
              label={t('timesheet.timesheetTo') ?? ''}
              rules={[{ required: true, message: t('common.fieldIsRequired') ?? '' }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space size='small'>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
              disabled={loading}
              loading={loading}
            >
              {t('common.update') ?? ''}
            </Button>
            <Link to={config.routes.timesheet}>{t('common.back') ?? ''}</Link>
          </Space>
        </Form.Item>
      </Form>
    </Page>
  );
};

export default TimesheetEdit;
