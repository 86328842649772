import { useTranslation } from 'react-i18next';
import { InvoiceStatus } from '../../components/pages/invoices/invoice.status';

interface StatusOption {
  value: number | null;
  label: string;
}

export function useInvoiceStatus(): StatusOption[] {
  const { t } = useTranslation('common');
  const statusOptions = [
    {
      tag: InvoiceStatus.APPROVED,
      value: InvoiceStatus.APPROVED,
      label: t('invoice.approved'),
    },
    {
      tag: InvoiceStatus.PENDING,
      value: InvoiceStatus.PENDING,
      label: t('invoice.pending'),
    },
    {
      tag: InvoiceStatus.PAID,
      value: InvoiceStatus.PAID,
      label: t('invoice.paid'),
    },
    {
      tag: InvoiceStatus.UNAPPROVED,
      value: InvoiceStatus.UNAPPROVED,
      label: t('invoice.unapproved'),
    },
    {
      tag: InvoiceStatus.INACTIVE,
      value: InvoiceStatus.INACTIVE,
      label: t('invoice.inactive'),
    },
  ];

  return statusOptions;
}
