import { useLocation, Navigate, Outlet } from 'react-router-dom';
import React from 'react';
import { useUser } from './pages/users/UserContext';

const Auth = ({ allowedRoles, redirect }: any): React.ReactElement | null => {
  const { user } = useUser();
  const { role } = user;
  const location = useLocation();

  return allowedRoles.find((allowedRole: string) => role === allowedRole) != null ? (
    <Outlet />
  ) : (
    <Navigate to={redirect} state={{ from: location }} replace />
  );
};

export default Auth;
