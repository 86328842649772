import { useCallback, useEffect } from 'react';
import { useFetch } from 'use-http';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import config from '../../../config';

const { api } = config;
const { baseUrl, forgotNewPassword } = api;

interface UseForgotNewPasswordType {
  loading: boolean;
  error: Error | undefined;
  submit: (data: { code: string; email: string; password: string }) => void;
}
export default function useForgotNewPassword(): UseForgotNewPasswordType {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { loading, error, post, data } = useFetch(`${baseUrl}${forgotNewPassword}`);

  const submit = useCallback(
    (data: { code: string; email: string; password: string }) => {
      post({
        ...data,
      })
        .then()
        .catch((err) => console.log(err));
    },
    [post],
  );

  useEffect(() => {
    if (!isNaN(data?.id)) {
      notification.success({
        message: 'Success',
        description: t('forgot.resetSuccess'),
        placement: 'topLeft',
      });
      navigate(config.routes.login);
      return;
    }
    if (error !== undefined) {
      notification.error({
        message: 'Error',
        description: t('forgot.resetError'),
        placement: 'topLeft',
      });
    }
  }, [error, data, t, navigate]);

  return { submit, error, loading };
}
