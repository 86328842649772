import React, { ReactElement, ReactNode, useContext, useMemo, useState } from 'react';
import { getPageSize } from '../../../common/DataTableDefaults';

export interface FilterOptions {
  email: string | undefined;
  lastName: string | undefined;
  status: number | undefined;
}

export interface PaginationOptions {
  page: number;
  pageSize: number;
}

interface UserListingType {
  filterOptions: FilterOptions;
  setFilterOptions: (filters: FilterOptions) => void;
  pagination: PaginationOptions;
  setPagination: (pagOptions: PaginationOptions) => void;
}

const defaultValue: UserListingType = {
  filterOptions: { email: undefined, lastName: undefined, status: undefined },
  setFilterOptions: () => {},

  pagination: { page: 0, pageSize: 0 },
  setPagination: () => {},
};

export const UserListingContext = React.createContext<UserListingType>(defaultValue);

export const UserListingProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    email: undefined,
    lastName: undefined,
    status: undefined,
  });

  const [pagination, setPagination] = useState<PaginationOptions>({
    page: 1,
    pageSize: getPageSize(),
  });

  const values = useMemo(() => {
    return {
      filterOptions,
      setFilterOptions,
      pagination,
      setPagination,
    };
  }, [filterOptions, pagination]);

  return <UserListingContext.Provider value={values}>{children}</UserListingContext.Provider>;
};

export const useUserListing = (): UserListingType => {
  return useContext(UserListingContext);
};
