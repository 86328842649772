import { useFetch } from 'use-http';
import { useCallback, useEffect } from 'react';
import { FormInstance, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import config from '../../../config';

interface UseTimesheetAddHookType {
  loading: boolean;
  error: Error | undefined;
  createTimesheet: (data: any) => void;
}

const { api } = config;
const { baseUrl, timesheetPath } = api;

export function useTimesheetAdd(
  isCheckboxChecked: boolean,
  form: FormInstance<any>,
): UseTimesheetAddHookType {
  const { t } = useTranslation('common');
  const { post, loading, error, data } = useFetch(`${baseUrl}${timesheetPath}`);
  const navigate = useNavigate();

  useEffect(() => {
    if (error !== undefined) {
      notification.error({
        message: 'Error',
        description: t('timesheet.saveError'),
        placement: 'topLeft',
      });
    }

    if (!isNaN(data?.id)) {
      notification.success({
        message: 'Success',
        description: t('timesheet.saveSuccess'),
        placement: 'topLeft',
      });

      if (isCheckboxChecked) {
        form.resetFields();
        navigate(config.routes.timesheetAdd);
      } else {
        navigate(config.routes.timesheet);
      }
    }
  }, [error, data, t, isCheckboxChecked, navigate, form]);

  const createTimesheet = useCallback(
    async (data: any) => {
      const timesheetData = {
        description: data.taskDescription,
        from: data.timesheetFrom.format('YYYY-MM-DD'),
        to: data.timesheetTo.format('YYYY-MM-DD'),
        projectId: data.project,
        hours: parseFloat(data.hours),
      };

      try {
        await post({
          ...timesheetData,
        });
      } catch (ex) {}
    },
    [post],
  );

  return {
    loading,
    error,
    createTimesheet,
  };
}
