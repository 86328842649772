import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, Col, DatePicker, Form, Row, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useUser } from '../../users/UserContext';
import { useInvoiceListing } from '../contexts/InvoiceListingContext';
import { useUserEmailFilterContext } from '../../../../general-purpose-contexts/UserEmailFilterContext';
import { useInvoiceStatus } from '../../../../hooks/invoices/useInvoiceStatus';
import { UserRole } from '../../users/user.role';
import { displaySearchParams } from '../../../../utils/searchParams';
import { rangePresets } from '../../../common/RangePresets';
import { getPageSize } from '../../../common/DataTableDefaults';

const { RangePicker } = DatePicker;

function InvoiceFilters(): React.ReactElement {
  const { t } = useTranslation('common');
  const [form] = Form.useForm();

  const { user } = useUser();
  const { role } = user;
  const statusOptions = useInvoiceStatus();
  const { options } = useUserEmailFilterContext();

  const { filterOptions, setFilterOptions, setPagination } = useInvoiceListing();

  const [, setSearchParams] = useSearchParams();

  const handleFilteringOptions = async (): Promise<void> => {
    const currentFilters = form.getFieldsValue(['period', 'status', 'userEmail']);
    currentFilters.from =
      currentFilters.period != null && currentFilters.period.length === 2
        ? currentFilters.period[0]
        : undefined;
    currentFilters.to =
      currentFilters.period != null && currentFilters.period.length === 2
        ? currentFilters.period[1]
        : undefined;

    setFilterOptions(currentFilters);
    setPagination({ page: 1, pageSize: getPageSize() });
    await displaySearchParams(currentFilters, setSearchParams);
    sessionStorage.setItem('invoice', window.location.search);
  };

  useEffect(() => {
    filterOptions.period = [filterOptions.from, filterOptions.to];
    form.setFieldsValue(filterOptions);
    setSearchParams(sessionStorage.getItem('invoice') ?? window.location.search);
  }, []);

  return (
    <Card>
      <Form
        name='filter-invoices'
        form={form}
        layout='vertical'
        wrapperCol={{ span: 20, offset: 0 }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item name='period' label={t('rangePicker.label') ?? ''}>
              <RangePicker presets={rangePresets} className='w-100' />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item name='status' label={t('invoice.status') ?? ''}>
              <Select options={statusOptions} allowClear showSearch></Select>
            </Form.Item>
          </Col>
          {role === UserRole.ADMIN ? (
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name='userEmail'
                label={t('invoice.userEmail') ?? ''}
                rules={[{ type: 'email' }]}
              >
                <Select style={{ width: '100%' }} allowClear showSearch options={options} />
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <Row justify='end'>
          <Col>
            <Button
              htmlType='button'
              icon={<SearchOutlined />}
              onClick={() => {
                handleFilteringOptions();
              }}
            >
              {t('common.filterTitle') ?? ''}
            </Button>
            <Button
              htmlType='reset'
              type={'text'}
              onClick={() => {
                form.resetFields();
                sessionStorage.setItem('invoice', '');
                handleFilteringOptions();
              }}
            >
              {t('common.clearBtn') ?? ''}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default InvoiceFilters;
