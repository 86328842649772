import React, { ReactElement, ReactNode, useContext, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import useFetch from 'use-http';

import useFetchTimesheets from '../../../../hooks/timesheets/useFetchTimesheets';

import {
  ColumnSortingOptions,
  FilterOptionsTimesheet,
  PaginationOptions,
  ProjectStatsType,
} from '../../timesheet/timesheet-types';

import config from '../../../../config';

interface TimesheetListingType {
  filterOptions: FilterOptionsTimesheet;
  setFilterOptions: (filters: FilterOptionsTimesheet) => void;
  pagination: PaginationOptions;
  setPagination: (pagOptions: PaginationOptions) => void;
  sort: ColumnSortingOptions;
  setSort: (sortOptions: ColumnSortingOptions) => void;

  projectStats: ProjectStatsType | undefined;
  loadingStats: boolean;
}

const defaultValue: TimesheetListingType = {
  filterOptions: {
    from: undefined,
    to: undefined,
    project: undefined,
    userEmail: undefined,
    period: undefined,
  },
  setFilterOptions: () => {},
  pagination: { page: 0, pageSize: 0 },
  setPagination: () => {},
  sort: { column: undefined, order: undefined },
  setSort: () => {},
  projectStats: {
    '': {
      totalHours: 0,
      totalCost: 0,
      nrTimesheets: 0,
      nrContributors: 0,
    },
    allProjects: { totalHours: 0 },
  },
  loadingStats: true,
};

const MONTHS_COUNT = 4;

export const TimesheetListingDashContext = React.createContext<TimesheetListingType>(defaultValue);

export const TimesheetListingDashProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [filterOptions, setFilterOptions] = useState<FilterOptionsTimesheet>({
    from: dayjs(new Date()).startOf('month').subtract(MONTHS_COUNT, 'months'),
    to: dayjs(new Date()).endOf('month'),
    project: undefined,
    userEmail: undefined,
    period: [
      dayjs(new Date()).startOf('month').subtract(MONTHS_COUNT, 'months'),
      dayjs(new Date()).endOf('month'),
    ],
  });

  const [pagination, setPagination] = useState<PaginationOptions>({
    page: 1,
    pageSize: 0,
  });

  const [sort, setSort] = useState<ColumnSortingOptions>({ column: undefined, order: undefined });

  const { url } = useFetchTimesheets(
    filterOptions,
    { page: 1, pageSize: 0 },
    {
      adminCasePath: config.api.timesheetPath,
      collabCasePath: '',
    },
    sort,
  );

  const queryParams: string = url.slice(url.indexOf('?'));
  const statsUrl: string = `${config.api.baseUrl}${config.api.timesheetPath}${config.api.timesheetGetProjectStats}${queryParams}`;

  const { data: projectStats, loading: loadingStats } = useFetch<ProjectStatsType>(
    statsUrl,
    { data: {} },
    [statsUrl],
  );

  const values = useMemo(() => {
    return {
      filterOptions,
      setFilterOptions,
      pagination,
      setPagination,
      sort,
      setSort,
      projectStats,
      loadingStats,
    };
  }, [
    filterOptions,
    setFilterOptions,
    pagination,
    setPagination,
    sort,
    setSort,
    projectStats,
    loadingStats,
  ]);

  return (
    <TimesheetListingDashContext.Provider value={values}>
      {children}
    </TimesheetListingDashContext.Provider>
  );
};

export const useTimesheetListingDash = (): TimesheetListingType => {
  return useContext(TimesheetListingDashContext);
};
