import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Col, Row, Space, Card, Input, Typography, Alert } from 'antd';
import { useTranslation } from 'react-i18next';

import s from './../../App.module.scss';
import config from '../../../config';
import { Link } from 'react-router-dom';
import useForgotPassword from './useForgotPassword';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation('common');
  const { Title } = Typography;

  const { submit, resetForm, submitted, loading, error } = useForgotPassword();

  return (
    <Row justify='center' align='middle' className={s.bgGradient} style={{ height: '100vh' }}>
      <div className={s.curve} />
      <Card className={s.cardShadow}>
        <Title level={4}>{t('forgot.title')}</Title>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Col sm={24} lg={24}>
            {submitted && error === undefined ? (
              <>
                <Row>{t('forgot.sendText')}</Row>
                <Row style={{ marginBottom: '16px' }}>{t('forgot.followText')}</Row>
                <Button type='primary' className='login-form-button' onClick={resetForm}>
                  {t('common.back') ?? ''}
                </Button>
              </>
            ) : (
              <>
                <Form
                  name='normal_forgot'
                  className='forgot-form'
                  initialValues={{ remember: true }}
                  layout='vertical'
                  onFinish={submit}
                >
                  <Form.Item
                    name='email'
                    label={t('login.emailLabel') ?? ''}
                    rules={[{ required: true, message: t('login.emailError') ?? '' }]}
                  >
                    <Input
                      autoComplete='off'
                      prefix={<UserOutlined className='site-form-item-icon' />}
                      placeholder={t('login.emailLabel') ?? ''}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Space size='small'>
                      <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button'
                        loading={loading}
                      >
                        {t('login.send') ?? ''}
                      </Button>
                      <Link to={config.routes.login}>{t('common.cancelBtn') ?? ''}</Link>
                    </Space>
                  </Form.Item>
                  {error != null ? <Alert message={t('forgot.submitError')} type='error' /> : ''}
                </Form>
              </>
            )}
          </Col>
        </Space>
      </Card>
    </Row>
  );
};

export default ForgotPassword;
