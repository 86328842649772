import React, { useCallback } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Button, Form, Input, Col, Row, Space, Typography, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFetch } from 'use-http';

import config from '../../../config';
import Page from '../../common/Page';

const { Title } = Typography;
const { api } = config;
const { baseUrl, usersChangePassword } = api;

const ChangePassword: React.FC = () => {
  const { t } = useTranslation('common');
  const { patch, loading } = useFetch(`${baseUrl}${usersChangePassword}`);

  const submit = useCallback(
    async ({ password }: { password: string }) => {
      try {
        await patch({ password });
        notification.success({
          message: t('common.success'),
          description: t('users.userUpdatedSuccessfully'),
          placement: 'topLeft',
        });
      } catch (ex) {
        console.log(ex);
        notification.error({
          message: t('common.error'),
          description: t('users.userUpdatedError'),
          placement: 'topLeft',
        });
      }
    },
    [patch, t],
  );

  return (
    <>
      <Page>
        <Title level={2}>{t('changePassword.title')}</Title>
        <Row>
          <Space className='w-100' direction='vertical' size='large'>
            <Col lg={4}>
              <Form
                onFinish={submit}
                name='change_password'
                className='password-form'
                initialValues={{ remember: true }}
              >
                <h3>{t('changePassword.passwordLabel') ?? ''}</h3>
                <Form.Item
                  name='password'
                  rules={[{ required: true, message: t('changePassword.passwordError') ?? '' }]}
                >
                  <Input
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    placeholder={t('changePassword.passwordPlaceholder') ?? ''}
                  />
                </Form.Item>
                <h3>{t('changePassword.rePasswordLabel') ?? ''}</h3>
                <Form.Item
                  name='password-repeat'
                  rules={[{ required: true, message: t('changePassword.rePasswordError') ?? '' }]}
                >
                  <Input
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    placeholder={t('changePassword.rePasswordPlaceholder') ?? ''}
                  />
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button
                      disabled={loading}
                      loading={loading}
                      type='primary'
                      htmlType='submit'
                      className='login-form-button'
                    >
                      {t('common.saveBtn') ?? ''}
                    </Button>
                    <Link to={config.routes.invoices}>{t('common.cancelBtn') ?? ''}</Link>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Space>
        </Row>
      </Page>
    </>
  );
};

export default ChangePassword;
