import React from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import Title from 'antd/lib/typography/Title';

import { useUser } from '../users/UserContext';
import { UserEmailFilterProvider } from '../../../general-purpose-contexts/UserEmailFilterContext';
import { TimesheetListingDashProvider } from './contexts/TimesheetListingDashContext';

import Page from '../../common/Page';
import DashboardFilters from './subcomponents/DashboardFilters';
import ProjectContributorsTable from './subcomponents/ProjectContributorsTable';
import ChartTimesheet from './subcomponents/ChartTimesheet';

import { UserRole } from '../users/user.role';

const Dashboard: React.FC = () => {
  const { t } = useTranslation('common');

  const {
    user: { role },
  } = useUser();

  return (
    <Page>
      <UserEmailFilterProvider>
        <TimesheetListingDashProvider>
          <Space direction='vertical' size='middle' className='w-100'>
            <Title level={3}>{t('dashboard.title')}</Title>
            <DashboardFilters />
            {role === UserRole.ADMIN && <ChartTimesheet />}
            <ProjectContributorsTable />
          </Space>
        </TimesheetListingDashProvider>
      </UserEmailFilterProvider>
    </Page>
  );
};

export default Dashboard;
