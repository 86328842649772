import { DefaultOptionType } from 'antd/es/select';
import { useMemo } from 'react';
import { useFetch } from 'use-http';

import config from '../../config';

export function useEmailFilter(): { options: DefaultOptionType[]; getEmails: () => Promise<void> } {
  const {
    data = [],
    error,
    get: getEmails,
  } = useFetch(`${config.api.baseUrl}${config.api.usersGetEmailAdresses}`, {}, []);

  const options = useMemo(() => {
    if (error !== undefined || !Array.isArray(data)) return [];
    return data.map((userData: { email: string }) => {
      const email = userData?.email ?? '';
      return {
        value: email,
        label: email,
      };
    });
  }, [data, error]);

  return { options, getEmails };
}
