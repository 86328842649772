export default {
  dataTable: {
    DEFAULT_PAGE_SIZE: 20,
  },
  lsKey: {
    INVOICES_FILTERS: 'INVOICES_FILTERS',
    TIMESHEET_FILTERS: 'TIMESHEET_FILTERS',
  },
  sskey: {
    INVOICE_FILTERS: 'INVOICE_FILTERS',
    TIMESHEET_FILTERS: 'TIMESHEET_FILTERS',
  },
  api: {
    baseUrl: process.env.REACT_APP_API_URL ?? 'http://localhost:3001',
    registerPath: '/users',
    usersPath: '/users',
    loginPath: '/users/login',
    usersMe: '/users/me',
    invoicesPath: '/invoices',
    invoicesMe: '/invoices/me',
    invoicesUploadPath: '/invoices/upload',
    invoiceFileDownloadPath: '/invoices/download/:fileName',
    invoiceFileDownloadIngPath: '/invoices/download/ing',
    invoicesDeletePath: '/invoices/:id',
    invoicesChangeStatusPath: '/invoices/:id/status/:status',
    invoicesStatsPath: '/invoices/expsenses-stats',
    projectsPath: '/projects',
    projectsActivePath: '/projects/active',
    projectsDeletePath: '/projects/:id',
    projectsEditPath: '/projects/:id',
    projectsWithManagers: '/projects/project-managers',
    usersDeletePath: '/users/:id',
    usersEditPath: '/users/:id',
    usersChangePassword: '/users/password',
    usersGetEmailAdresses: '/users/emails',
    usersGetEmailAdressesAndIds: '/users/emails-and-ids',
    timesheetPath: '/timesheet',
    timesheetMe: '/timesheet/me',
    timesheetDeletePath: '/timesheet/:id',
    timesheetEditPath: '/timesheet/:id',
    timesheetStatsPath: '/timesheet/stats',
    timesheetStatsPerProjectPath: '/timesheet/stats-per-project',
    timesheetsDownload: '/timesheet/download-csv',
    timesheetGetProjectStats: '/project-stats',
    forgotPassword: '/users/forgot',
    forgotNewPassword: '/users/reset',
    costPath: '/cost',
    costEditPath: '/cost/:id',
    costDeletePath: '/cost/:id',
  },
  routes: {
    dashboard: '/dashboard',
    login: '/login',
    register: '/register',
    invoices: '/invoices',
    invoicesAdd: '/invoices/add',
    users: '/users',
    projects: '/projects',
    projectsEdit: '/projects/edit/:id',
    projectsAdd: '/projects/add',
    changePassword: '/change-password',
    profile: '/profile',
    reset: '/reset-password',
    forgot: '/forgot',
    forgotNew: '/forgot/new/:code/:email',
    home: '/',
    usersEdit: '/users/edit/:id',
    timesheet: '/timesheet',
    timesheetAdd: '/timesheet/add',
    timesheetEdit: '/timesheet/edit/:id',
    cost: '/cost',
    costAdd: '/cost/add',
    costEdit: '/cost/edit/:id',
  },
  theme: {
    token: {
      fontFamily: 'Open Sans, sans-serif',
    },
  },
};
