import React, { ReactElement, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts'
import config from '../../config';

export default function PrivateRoute({ children }: { children: ReactNode }): ReactElement {
  const [token] = useLocalStorage('token', { bearer: '' });
  const navigate = useNavigate();

  useEffect(() => {
    if (token.bearer === '') {
      navigate(config.routes.login);
    }
  }, [navigate, token]);

  return <div>{children}</div>;
}
