import { z } from 'zod';
import dayjs from 'dayjs';

import { isValidDayjsString, isValidPage, isValidProjectId, isValidStatus } from './searchParams';

import {
  FilterOptionsInvoice,
  PaginationOptions,
} from '../components/pages/invoices/invoice-types';
import { FilterOptionsTimesheet } from '../components/pages/timesheet/timesheet-types';
import { FilterOptionsCost } from '../components/pages/cost/cost-types';
import { getPageSize } from '../components/common/DataTableDefaults';

export function initialPagValue(queryParams: URLSearchParams): PaginationOptions {
  const pages = z.object({
    page: z.string().refine(isValidPage, { message: 'Invalid status in url' }).nullable(),
    pageSize: z.string().refine(isValidPage, { message: 'Invalid status in url' }).nullable(),
  });

  const page: string | null = queryParams.get('page');
  const pageSize: string | null = queryParams.get('pageSize');

  if (pages.safeParse({ page, pageSize }).success) {
    return {
      page: page !== null ? parseInt(page) : 1,
      pageSize: pageSize !== null ? parseInt(pageSize) : getPageSize(),
    };
  }

  return {
    page: 1,
    pageSize: getPageSize(),
  };
}

export function initialFilterValsInvoice(queryParams: URLSearchParams): FilterOptionsInvoice {
  const filters = z.object({
    from: z
      .string()
      .refine(isValidDayjsString, { message: 'Invalid dayjs string in current url' })
      .nullable(),
    to: z
      .string()
      .refine(isValidDayjsString, { message: 'Invalid dayjs string in current url' })
      .nullable(),
    status: z.string().refine(isValidStatus, { message: 'Invalid status in url' }).nullable(),
    userEmail: z.string().email().nullable(),
  });

  const from: string | null = queryParams.get('from');
  const to: string | null = queryParams.get('to');
  const status: string | null = queryParams.get('status');
  const userEmail: string | null = queryParams.get('userEmail');

  if (filters.safeParse({ from, to, status, userEmail }).success) {
    return {
      from: from !== null ? dayjs(from) : undefined,
      to: to !== null ? dayjs(to) : undefined,
      status: status !== null ? parseInt(status) : undefined,
      userEmail: userEmail ?? undefined,
    };
  }

  return {
    from: undefined,
    to: undefined,
    status: undefined,
    userEmail: undefined,
  };
}

export function initialFilterValsTimesheet(queryParams: URLSearchParams): FilterOptionsTimesheet {
  const filters = z.object({
    from: z
      .string()
      .refine(isValidDayjsString, { message: 'Invalid dayjs string in current url' })
      .nullable(),
    to: z
      .string()
      .refine(isValidDayjsString, { message: 'Invalid dayjs string in current url' })
      .nullable(),
    project: z
      .string()
      .refine(isValidProjectId, { message: 'Invalid project id in url' })
      .nullable(),
    userEmail: z.string().email().nullable(),
  });

  const from: string | null = queryParams.get('from');
  const to: string | null = queryParams.get('to');
  const project: string | null = queryParams.get('project');
  const userEmail: string | null = queryParams.get('userEmail');

  if (filters.safeParse({ from, to, project, userEmail }).success) {
    return {
      from: from !== null ? dayjs(from) : undefined,
      to: to !== null ? dayjs(to) : undefined,
      project: project !== null ? parseInt(project) : undefined,
      userEmail: userEmail ?? undefined,
    };
  }

  return {
    from: undefined,
    to: undefined,
    project: undefined,
    userEmail: undefined,
  };
}

export function initialFilterValsCost(queryParams: URLSearchParams): FilterOptionsCost {
  const filters = z.object({
    from: z
      .string()
      .refine(isValidDayjsString, { message: 'Invalid dayjs string in current url' })
      .nullable(),
    to: z
      .string()
      .refine(isValidDayjsString, { message: 'Invalid dayjs string in current url' })
      .nullable(),
    project: z
      .string()
      .refine(isValidProjectId, { message: 'Invalid project id in url' })
      .nullable(),
    userEmail: z.string().email().nullable(),
  });

  const from: string | null = queryParams.get('from');
  const to: string | null = queryParams.get('to');
  const project: string | null = queryParams.get('project');
  const userEmail: string | null = queryParams.get('userEmail');

  if (filters.safeParse({ from, to, project, userEmail }).success) {
    return {
      from: from !== null ? dayjs(from) : undefined,
      to: to !== null ? dayjs(to) : undefined,
      project: project !== null ? parseInt(project) : undefined,
      userEmail: userEmail ?? undefined,
    };
  }

  return {
    from: undefined,
    to: undefined,
    project: undefined,
    userEmail: undefined,
  };
}
