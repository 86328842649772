import React from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useFetch } from 'use-http';
import config from '../../../../config';
import { useTimesheetListing } from '../contexts/TimesheetListingContext';
import { downloadFileInBrowser } from '../../../../utils/file';

function DownloadTimesheetsButton(): React.ReactElement {
  const { url } = useTimesheetListing();
  const queryParams: string = url.slice(url.indexOf('?'));

  const { get: downloadTimesheetsCSV } = useFetch(
    `${config.api.baseUrl}${config.api.timesheetsDownload}`,
    {
      responseType: 'blob',
    },
  );

  const handleClick = async (): Promise<void> => {
    const fileDownloadData = await downloadTimesheetsCSV(queryParams);
    downloadFileInBrowser(
      fileDownloadData,
      `${config.api.baseUrl}${config.api.timesheetsDownload}${queryParams}`,
      'timesheets.csv',
    );
  };

  return (
    <Button icon={<DownloadOutlined />} onClick={handleClick}>
      Download
    </Button>
  );
}

export default DownloadTimesheetsButton;
