import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from './Page';
import { Result } from 'antd';

const NotFound: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <Page>
      <Result status='404' title='404' subTitle={t('404.title') ?? ''} />
      <p style={{ textAlign: 'center' }}>
        <Link to='/'>{t('404.link') ?? ''}</Link>
      </p>
    </Page>
  );
};

export default NotFound;
