import React, { useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Col, Row, Space, Card, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import s from './../../App.module.scss';
import config from '../../../config';
import { Link, useParams } from 'react-router-dom';
import useForgotNewPassword from './useForgotNewPassword';

const ForgotNewPassword: React.FC = () => {
  const { t } = useTranslation('common');
  const { Title } = Typography;
  const { code, email } = useParams();
  const [form] = Form.useForm();

  const { submit, loading } = useForgotNewPassword();

  useEffect(() => {
    if (email != null && code != null) {
      form.setFieldsValue({ code });
      form.setFieldsValue({ email });
    }
  }, [code, email, form]);

  return (
    <Row justify='center' align='middle' className={s.bgGradient} style={{ height: '100vh' }}>
      <div className={s.curve} />
      <Card className={s.cardShadow}>
        <Title level={4}>{t('forgot.title')}</Title>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Col sm={24} lg={24}>
            <Form
              name='new_password_forgot'
              className='forgot-form-new'
              initialValues={{ remember: true }}
              layout='vertical'
              form={form}
              onFinish={submit}
            >
              <Form.Item
                name='email'
                label={t('login.emailLabel') ?? ''}
                rules={[{ required: true, message: t('login.emailError') ?? '' }]}
              >
                <Input
                  disabled={true}
                  autoComplete='off'
                  prefix={<UserOutlined className='site-form-item-icon' />}
                  placeholder={t('login.emailLabel') ?? ''}
                />
              </Form.Item>
              <Form.Item
                name='code'
                label={t('forgot.codeLabel') ?? ''}
                rules={[{ required: true, message: t('forgot.codeError') ?? '' }]}
              >
                <Input
                  disabled={true}
                  autoComplete='off'
                  prefix={<UserOutlined className='site-form-item-icon' />}
                  placeholder={t('login.emailLabel') ?? ''}
                />
              </Form.Item>
              <Form.Item
                name='password'
                label={t('register.passLabel') ?? ''}
                rules={[
                  {
                    required: true,
                    message: t('register.passErrorEmpty') ?? '',
                  },
                  {
                    min: 7,
                    message: t('forgot.passTooLow') ?? '',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name='confirm'
                label={t('register.confirmPassLabel') ?? ''}
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: t('register.confirmPassErrorEmpty') ?? '',
                  },
                  ({ getFieldValue }) => ({
                    async validator(_, value) {
                      if (
                        value === undefined ||
                        value === '' ||
                        getFieldValue('password') === value
                      ) {
                        return await Promise.resolve();
                      }
                      throw new Error(t('register.confirmPassError') ?? '');
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Space size='small'>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='login-form-button'
                    loading={loading}
                  >
                    {t('login.send') ?? ''}
                  </Button>
                  <Link to={config.routes.login}>{t('common.cancelBtn') ?? ''}</Link>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Space>
      </Card>
    </Row>
  );
};

export default ForgotNewPassword;
