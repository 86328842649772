import { useFetch } from 'use-http';
import config from '../../../config';
import { useMemo } from 'react';

const {
  api: { baseUrl, projectsActivePath },
} = config;

interface ProjectList {
  projectOptions: Array<{ label: string; value: number }>;
}

export function useProjectList(): ProjectList {
  const { data = [] } = useFetch(`${baseUrl}${projectsActivePath}`, {}, []);
  const projectOptions = useMemo(() => {
    if (Array.isArray(data)) {
      return data.map((item: { id: number; name: string }) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    }
    return [];
  }, [data]);
  return {
    projectOptions,
  };
}
