import { useFetch } from 'use-http';
import config from '../../../config';
import { useMemo } from 'react';
import { UserType } from './user-types';

const {
  api: { baseUrl, usersPath },
} = config;

interface UserList {
  userOptions: Array<{ label: string; value: number }>;
}

export function useUserList(): UserList {
  const { data = [] } = useFetch(`${baseUrl}${usersPath}?limit=0`, {}, []);

  const userOptions = useMemo(() => {
    const userData = data.data;
    if (Array.isArray(userData)) {
      return userData.map((user: UserType) => {
        return {
          value: user.id,
          label: user.email,
        };
      });
    }
    return [];
  }, [data]);

  return {
    userOptions,
  };
}
