import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Col, Row, Space, Card, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import s from './../../App.module.scss';
import config from '../../../config';
import useLogin from './useLogin';
import LogoBlackSvg from '../../../assets/logo-black.svg';

const Login: React.FC = () => {
  const { t } = useTranslation('common');
  const { login, error, loading } = useLogin();

  return (
    <Row justify='center' align='middle' className={s.bgGradient} style={{ height: '100vh' }}>
      <Card className={s.cardShadow}>
        <div className={s.logoContainer}>
          <img src={LogoBlackSvg} alt='InvoGenie' />
        </div>
        <Space direction='vertical' style={{ width: '100%' }}>
          {error != null ? <Alert message={t('login.emailPassWrong')} type='error' /> : ''}
          <Col sm={24} lg={24}>
            <Form
              name='normal_login'
              className='login-form'
              initialValues={{ remember: true }}
              layout='vertical'
              onFinish={login}
            >
              <Form.Item
                name='email'
                label={t('login.emailLabel') ?? ''}
                rules={[{ required: true, message: t('login.emailError') ?? '' }]}
              >
                <Input
                  autoComplete='off'
                  prefix={<UserOutlined className='site-form-item-icon' />}
                  placeholder={t('login.usernamePlaceholder') ?? ''}
                />
              </Form.Item>
              <Form.Item
                name='password'
                label={t('login.passwordPlaceholder') ?? ''}
                rules={[{ required: true, message: t('login.passwordError') ?? '' }]}
              >
                <Input
                  prefix={<LockOutlined className='site-form-item-icon' />}
                  type='password'
                  autoComplete='off'
                  placeholder={t('login.passwordPlaceholder') ?? ''}
                />
              </Form.Item>
              <Form.Item>
                <Link to={config.routes.forgot}>{t('login.forgot')}</Link>
              </Form.Item>
              <Form.Item>
                <Space size='small'>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='login-form-button'
                    loading={loading}
                  >
                    {t('login.loginLink') ?? ''}
                  </Button>
                  {t('login.orText') ?? ''}{' '}
                  <Link to={config.routes.register}>{t('login.registerLink') ?? ''}</Link>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Space>
      </Card>
    </Row>
  );
};

export default Login;
