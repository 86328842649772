import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';

import { notification } from 'antd';

import config from '../../config';

const { api } = config;
const { baseUrl, projectsDeletePath } = api;

interface UseProjectHookType {
  deleteProject: (id: string) => Promise<void>;
  loadingDelete: boolean;
}

export function useModifyProject(): UseProjectHookType {
  const { t } = useTranslation('common');

  const {
    delete: remove,
    loading: loadingDelete,
    response: deleteResponse,
    error: deleteError,
  } = useFetch(baseUrl);

  useEffect(() => {
    if (deleteResponse.ok) {
      notification.success({
        message: t('common.success'),
        description: t('project.deleteProjectSuccessfully'),
        placement: 'topLeft',
      });
    }
  }, [deleteResponse.ok, t]);

  useEffect(() => {
    if (deleteError != null) {
      notification.error({
        message: t('common.error'),
        description: t('project.deleteProjectFailed'),
        placement: 'topLeft',
      });
    }
  }, [deleteError, t]);

  const deleteProject = useCallback(
    async (id: string) => {
      try {
        await remove(`${projectsDeletePath}`.replace(':id', id));
      } catch (ex) {
        console.log(ex);
      }
    },
    [remove],
  );

  return {
    deleteProject,
    loadingDelete,
  };
}
