export function formatNumber(number: any): any {
  if (isNaN(parseFloat(number))) {
    return 'NaN';
  }

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(parseFloat(number));
}
