import config from '../../config';

export function getPageSize(): number {
  let pageSizeToSet = config.dataTable.DEFAULT_PAGE_SIZE;
  const storagePageSize = localStorage.getItem('pageSize');

  if (storagePageSize == null || Number(storagePageSize) <= 0) {
    localStorage.setItem('pageSize', `${pageSizeToSet}`);
  } else {
    pageSizeToSet = Number(storagePageSize);
    localStorage.setItem('pageSize', `${pageSizeToSet}`);
  }

  return pageSizeToSet;
}
