import { TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';
import i18next from 'i18next';

export const rangePresets: TimeRangePickerProps['presets'] = [
  {
    label: i18next.t('common:rangePicker.last7Days') ?? '',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  { label: i18next.t('common:rangePicker.currentMonth') ?? '', value: [dayjs().date(1), dayjs()] },
  {
    label: i18next.t('common:rangePicker.lastMonth') ?? '',
    value: [dayjs().add(-1, 'month'), dayjs()],
  },
  {
    label: i18next.t('common:rangePicker.last2Months') ?? '',
    value: [dayjs().add(-2, 'month'), dayjs()],
  },
  {
    label: i18next.t('common:rangePicker.last3Months') ?? '',
    value: [dayjs().add(-3, 'month'), dayjs()],
  },
  {
    label: i18next.t('common:rangePicker.last4Months') ?? '',
    value: [dayjs().add(-4, 'month'), dayjs()],
  },
  {
    label: i18next.t('common:rangePicker.last6Months') ?? '',
    value: [dayjs().add(-6, 'month'), dayjs()],
  },
];
