import { useFetch } from 'use-http';
import { useCallback, useEffect } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

import config from '../../../config';
import { useNavigate } from 'react-router-dom';

interface UseInvoiceAddHookType {
  loading: boolean;
  error: Error | undefined;
  createInvoice: (data: any) => void;
}

const { api } = config;
const { baseUrl, invoicesPath } = api;

export function useInvoiceAdd(): UseInvoiceAddHookType {
  const { t } = useTranslation('common');
  const { post, loading, error, data } = useFetch(`${baseUrl}${invoicesPath}`);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isNaN(data?.id)) {
      notification.success({
        message: 'Success',
        description: t('invoice.saveSuccess'),
        placement: 'topLeft',
      });
      navigate(config.routes.invoices);
      return;
    }
    if (error !== undefined) {
      notification.error({
        message: 'Error',
        description: t('invoice.saveError'),
        placement: 'topLeft',
      });
    }
  }, [error, data, t, navigate]);

  const createInvoice = useCallback(
    async (data: any) => {
      const invoiceData = {
        number: data.invoiceNo,
        amount: +data.sum,
        description: data.description,
        bankAccount: data.bankAccount,
        companyName: data.companyName,
        fileName: data.file?.file?.response?.fileName,
      };

      try {
        await post({
          ...invoiceData,
        });
      } catch (ex) {}
    },
    [post],
  );

  return {
    loading,
    error,
    createInvoice,
  };
}
