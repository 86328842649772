import { Interceptors } from 'use-http/dist/cjs/types';
import { useNavigate } from 'react-router-dom';
import config from '../config';

interface UseAppHookType {
  options: {
    interceptors: Interceptors;
  };
}

export function useApp(): UseAppHookType {
  const navigate = useNavigate();
  const options: any = {
    persist: false,
    cachePolicy: 'no-cache',
    interceptors: {
      request: async ({ options }: any) => {
        const lsToken = localStorage.getItem('token');
        let token = { bearer: '' };
        try {
          token = JSON.parse(lsToken ?? '{}');
        } catch (ex) {
          //
        }
        if (token?.bearer !== '') {
          options.headers.Authorization = `Bearer ${token.bearer}`;
        }
        return options;
      },
      response: ({ response }: any) => {
        if (response?.data?.statusCode === 401) {
          navigate(config.routes.login);
        }
        return response;
      },
    },
  };

  return {
    options,
  };
}
