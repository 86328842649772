import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { ProjectStatsType } from '../../timesheet/timesheet-types';
import { useTimesheetListingDash } from '../contexts/TimesheetListingDashContext';
import { formatMoney } from '../../../../utils/formatMoney';

interface ProjectContributorsType {
  projectName: string;
  hours: number;
  cost?: number;
  projectContributors: number | undefined;
}

function ProjectContributorsTable(): React.ReactElement {
  const { projectStats, loadingStats } = useTimesheetListingDash();

  function transformDataForTable(
    projectStats: ProjectStatsType | undefined,
  ): ProjectContributorsType[] {
    if (typeof projectStats !== 'undefined') {
      return Object.keys(projectStats).map((project) => ({
        projectName: project === 'allProjects' ? 'Total' : project,
        hours: projectStats[project].totalHours,
        cost: projectStats[project].totalCost,
        projectContributors: projectStats[project].nrContributors,
      }));
    } else {
      return [];
    }
  }

  const columns: ColumnsType<ProjectContributorsType> = [
    { title: 'Project', dataIndex: 'projectName', key: 'projectName' },
    { title: 'Contributors', dataIndex: 'projectContributors', key: 'projectContributors' },
    { title: 'Hours', dataIndex: 'hours', key: 'hours' },
    {
      title: 'Cost (EUR)',
      dataIndex: 'cost',
      key: 'cost',
      render: (cost) => formatMoney(cost),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={transformDataForTable(projectStats)}
      loading={loadingStats}
      bordered
      pagination={{
        pageSize: 20,
        total: typeof projectStats !== 'undefined' ? Object.keys(projectStats).length : 0,
        position: ['bottomCenter'],
      }}
    />
  );
}

export default ProjectContributorsTable;
